
var skillImage = {};
skillImage['평타'] = 'http://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/991.png';

skillImage['APG-63'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/572.png';
skillImage['B.C 트레이닝'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/266.png';
skillImage['B.G 파이어툴'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/258.png';
skillImage['C.Q.B Specter 5'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/224.png';
skillImage['C4'] = 'https://dundam.xyz/img/skill/c4.png';
skillImage['C4 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/556.png';
skillImage['CEAB-2'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/296.png';
skillImage['EMP 스톰'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/260.png';
skillImage['Ex-S 바이퍼'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/040.png';
skillImage['Ex-S 바이퍼 Zero'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/274.png';
skillImage['Ex-S 바이퍼 Zero 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/594.png';
skillImage['Ex-S 바이퍼 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/390.png';
skillImage['Ez-10 카운터어택'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/282.png';
skillImage['Ez-10 카운터어택 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/600.png';
skillImage['Ez-8 카운트다운'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/024.png';
skillImage['Ez-8 카운트다운 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/320.png';
skillImage['FM-31 그레네이드 런처'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/342.png';
skillImage['FM-31 그레네이드 런처 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/422.png';
skillImage['FM-92 mk2 랜서'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/240.png';
skillImage['FM-92 mk2 랜서 SW'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/278.png';
skillImage['FM-92 mk2 랜서 SW 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/598.png';
skillImage['FM-92 mk2 랜서 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/330.png';
skillImage['FM-92 스팅어'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/188.png';
skillImage['FM-92 스팅어 SW'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/268.png';
skillImage['FM-92 스팅어 SW 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/592.png';
skillImage['FM-92 스팅어 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/324.png';
skillImage['FSC-7'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/570.png';
skillImage['FSC-7 라이트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/570.png';
skillImage['G 로봇 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/508.png';
skillImage['G 마그네틱'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/544.png';
skillImage['G 익스텐션'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/258.png';
skillImage['G-1 코로나'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/174.png';
skillImage['G-1 코로나 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/310.png';
skillImage['G-14 파열류탄'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/140.png';
skillImage['G-18C 빙결류탄'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/142.png';
skillImage['G-35L 섬광류탄'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/144.png';
skillImage['G-38ARG 반응류탄'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/458.png';
skillImage['G-61 중력류탄'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/532.png';
skillImage['G-61 중력류탄 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/604.png';
skillImage['G-96 열압력유탄'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/466.png';
skillImage['G-Bomb'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/042.png';
skillImage['G-Bomb 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/092.png';
skillImage['G-CTF'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/056.png';
skillImage['G-CTF 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/102.png';
skillImage['G-S.P. 팔콘'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/588.png';
skillImage['G-Widow'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/126.png';
skillImage['G-X 스타더스트'] = 'https://bbscdn.df.nexon.com/data6/commu/202101/104925_5ff3c5a582019.png';
skillImage['G-오퍼레이터'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/500.png';
skillImage['GW-16 발트슈타인'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/02/icon/636.png';
skillImage['Hold & Go'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/526.png';
skillImage['HS-1 친구들'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/438.png';
skillImage['HS-12 플라즈마'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/586.png';
skillImage['HS-12 플라즈마 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/608.png';
skillImage['M-137 개틀링건'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/014.png';
skillImage['M-137 개틀링건 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/326.png';
skillImage['M-3 화염방사기'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/020.png';
skillImage['M-3 화염방사기 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/306.png';
skillImage['M18 클레이모어'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/528.png';
skillImage['M18 클레이모어 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/554.png';
skillImage['MLDRS-95'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/02/icon/628.png';
skillImage['MSC-7'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/576.png';
skillImage['PT-15 프로토타입'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/484.png';
skillImage['RX-60 트랩러너'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/530.png';
skillImage['RX-60 트랩러너 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/548.png';
skillImage['RX-78 랜드러너'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/026.png';
skillImage['RX-78 랜드러너 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/290.png';
skillImage['S.O.P.H.I.A'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/02/icon/632.png';
skillImage['SSTH'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/396.png';
skillImage['TX-45 A-Team'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/516.png';
skillImage['TX-80 디멘션 러너'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/442.png';
skillImage['UHT-03 플레게톤'] = '//bbscdn.df.nexon.com/data6/commu/202101/104823_5ff3c5673a2d9.png';
skillImage['X-1 익스트루더'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/172.png';
skillImage['X-1 익스트루더 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/366.png';
skillImage['가드'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/024.png';
skillImage['가드 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/284.png';
skillImage['가디언 라이딩'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/058.png';
skillImage['가디언 라이딩 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/096.png';
skillImage['가장 강한 녀석은 누구냐?'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/244.png';
skillImage['각성의 실마리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/999/icon/120.png';
skillImage['갈!'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/476.png';
skillImage['갈증'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/282.png';
skillImage['강권'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/080.png';
skillImage['강렬한 테이크 다운'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/070.png';
skillImage['강습'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/154.png';
skillImage['강습 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/196.png';
skillImage['강습유성타'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/196.png';
skillImage['강습유성타 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/356.png';
skillImage['강습펀치'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/275.png';
skillImage['강인한 신념'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/084.png';
skillImage['강타'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/148.png';
skillImage['강타 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/150.png';
skillImage['강화 투척'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/092.png';
skillImage['강화탄'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/004.png';
skillImage['개조 슈르르'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/246.png';
skillImage['개조형 파진포 : 연화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/240.png';
skillImage['개틀링 펀치'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/443.png';
skillImage['개틀링 펀치 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/496.png';
skillImage['거병 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/284.png';
skillImage['거선풍'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/136.png';
skillImage['거선풍 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/236.png';
skillImage['거압'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/216.png';
skillImage['거압 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/288.png';
skillImage['건가드'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/044.png';
skillImage['건가드 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/288.png';
skillImage['건블레이드'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/470.png';
skillImage['건틀릿 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/290.png';
skillImage['검귀강령 : 식'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/534.png';
skillImage['검귀의 가죽 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/572.png';
skillImage['검귀의 도 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/584.png';
skillImage['검기상인'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/656.png';
skillImage['검마격살'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/062.png';
skillImage['검마격살 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/130.png';
skillImage['검막'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/1/024.png';
skillImage['검막 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/1/284.png';
skillImage['검선지경'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/403.png';
skillImage['검은 눈'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/188.png';
skillImage['검은 숲의 가시나무'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/731.png';
skillImage['검은 충격'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/170.png';
skillImage['검은 충격 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/415.png';
skillImage['게이볼그 펀치'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/162.png';
skillImage['겟 온 파이어'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/130.png';
skillImage['겟 온 파이어 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/304.png';
skillImage['격노'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/564.png';
skillImage['격노 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/566.png';
skillImage['격렬한 폭풍의 가죽 갑옷 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/378.png';
skillImage['견고의 대검 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/148.png';
skillImage['결벽증'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/238.png';
skillImage['결의'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/142.png';
skillImage['경갑 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/0/016.png';
skillImage['경계망상'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/250.png';
skillImage['경전지화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/375.png';
skillImage['계승자'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/348.png';
skillImage['계시 : 아리아'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/090.png';
skillImage['계시 : 크루세이드'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/258.png';
skillImage['계약소환 : 검은기사 산도르'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/036.png';
skillImage['계약소환 : 검은기사 산도르 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/396.png';
skillImage['계약소환 : 검은기사 산도르 교감'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/586.png';
skillImage['계약소환 : 기갑 호도르'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/034.png';
skillImage['계약소환 : 기갑 호도르 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/358.png';
skillImage['계약소환 : 루이즈 언니!'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/166.png';
skillImage['계약소환 : 루이즈 언니! 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/362.png';
skillImage['계약소환 : 루이즈 언니! 교감'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/588.png';
skillImage['계약소환 : 마계화 아우쿠소'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/162.png';
skillImage['계약소환 : 마계화 아우쿠소 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/406.png';
skillImage['계약소환 : 마계화 아우쿠소 교감'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/587.png';
skillImage['계약소환 : 정복자 카시야스'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/218.png';
skillImage['계약소환 : 정복자 카시야스 천귀살'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/826.png';
skillImage['계약소환 : 타우킹 쿠루타'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/582.png';
skillImage['계약소환 : 타우킹 쿠루타 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/611.png';
skillImage['계약소환 : 타우킹 쿠루타 교감'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/589.png';
skillImage['계약소환 : 프리트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/208.png';
skillImage['계약소환 : 프리트 교감'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/585.png';
skillImage['계약소환 : 프리트 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/430.png';
skillImage['계열 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/10/icon/644.png';
skillImage['고각도 슬라이딩'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/080.png';
skillImage['고결한 격노'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/176.png';
skillImage['고결한 격노 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/216.png';
skillImage['고기동 메카 : 볼트 MX'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/504.png';
skillImage['고대의 도서관'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/020.png';
skillImage['고대의 맹약'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/343.png';
skillImage['고어 크로스'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/132.png';
skillImage['고어 크로스 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/222.png';
skillImage['고저스 컴비네이션'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/082.png';
skillImage['고저스 컴비네이션 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/256.png';
skillImage['고출력 매직 미사일'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/240.png';
skillImage['고출력 매직 미사일 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/298.png';
skillImage['고통의 정원'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/767.png';
skillImage['고통의 희열'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/014.png';
skillImage['고행'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/274.png';
skillImage['곡옥 5히트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/353.png';
skillImage['공격 금지'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/174.png';
skillImage['공력의 구'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/182.png';
skillImage['공력의 구 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/250.png';
skillImage['공명'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/050.png';
skillImage['공아'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/010.png';
skillImage['공아 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/092.png';
skillImage['공의 식신 - 백호'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/144.png';
skillImage['공의 식신 - 백호 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/278.png';
skillImage['공중 랜턴 파이어'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/156.png';
skillImage['공중 밟기'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/008.png';
skillImage['공중 밟기 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/178.png';
skillImage['공중 연속 베기'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/014.png';
skillImage['공중 전투 메카 : 템페스터'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/148.png';
skillImage['공중 전투 메카 : 템페스터 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/386.png';
skillImage['공중 전투 메카 : 템페스터 얼티밋'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/148.png';
skillImage['공중 전투 메카 : 템페스터 지속딜 7초'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/148.png';
skillImage['공중 전폭 메카 : 게일 포스'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/216.png';
skillImage['공중 전폭 메카 : 게일 포스 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/386.png';
skillImage['공중 전폭 메카 : 게일 포스 자폭'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/216.png';
skillImage['공중 전폭 메카 : 게일 포스 지속딜 7초'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/216.png';
skillImage['공중사격'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/074.png';
skillImage['공진'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/148.png';
skillImage['공진 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/180.png';
skillImage['공참타'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/018.png';
skillImage['공참타 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/346.png';
skillImage['관통탄'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/350.png';
skillImage['광검 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/096.png';
skillImage['광검 사용 가능'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/068.png';
skillImage['광기'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/492.png';
skillImage['광룡분쇄창'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/286.png';
skillImage['광룡십삼장'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/354.png';
skillImage['광룡십삼장 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/382.png';
skillImage['광명의 챠크라'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/116.png';
skillImage['광적인 믿음'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/178.png';
skillImage['광창 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/162.png';
skillImage['광충노도'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/186.png';
skillImage['광충노도 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/270.png';
skillImage['광폭 : 흑화연창'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/266.png';
skillImage['광폭혈쇄'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/154.png';
skillImage['광폭혈쇄 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/312.png';
skillImage['광폭화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/076.png';
skillImage['광풍나선력'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/258.png';
skillImage['광호천지파'] = 'https://bbscdn.df.nexon.com/data6/commu/202002/152645_5e390ea534bf7.png';
skillImage['광휘의 넨'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/322.png';
skillImage['광휘의 팔척곡옥'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/353.png';
skillImage['괴리 : 금단의 니알리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/266.png';
skillImage['괴리 : 금단의 니알리 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/298.png';
skillImage['괴리 : 디멘션 할로우'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/244.png';
skillImage['괴리 : 모서리의 짐승'] = 'https://bbscdn.df.nexon.com/data6/commu/202011/105146_5fbdb8b205cc7.png';
skillImage['괴리 : 저편의 자장가'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/406.png';
skillImage['괴리 : 칙 잇'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/282.png';
skillImage['괴리 : 칙 잇 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/428.png';
skillImage['괴리 : 크로울링 미스트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/254.png';
skillImage['괴리 : 패러사이트 스웜'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/274.png';
skillImage['괴리 : 패러사이트 스웜 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/304.png';
skillImage['괴리 : 할리퀸'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/270.png';
skillImage['괴리 : 할리퀸 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/300.png';
skillImage['괴리 : 형용할 수 없는 공포'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/278.png';
skillImage['괴멸의 복음'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/530.png';
skillImage['교감'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/617.png';
skillImage['교차 사격'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/512.png';
skillImage['교차 사격 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/558.png';
skillImage['구속의 가시넝쿨'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/725.png';
skillImage['구속의 가시넝쿨 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/751.png';
skillImage['구속제압부'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/140.png';
skillImage['구속제압부 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/150.png';
skillImage['구옥지화 : 카마이타치'] = 'https://bbscdn.df.nexon.com/data6/commu/202010/151129_5f97ba110e8c0.png';
skillImage['구천강림 : 귀문'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/662.png';
skillImage['군단 정렬'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/138.png';
skillImage['군단 정렬 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/176.png';
skillImage['군단 해산'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/409.png';
skillImage['굳건한 의지'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/522.png';
skillImage['궁극의 검 : 레바테인'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/357.png';
skillImage['궁극의 넨 가드'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/206.png';
skillImage['권총의 춤'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/224.png';
skillImage['권총의 춤 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/316.png';
skillImage['권투글러브 사용 가능'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/078.png';
skillImage['귀검편'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/332.png';
skillImage['귀검편 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/334.png';
skillImage['귀문반'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/140.png';
skillImage['귀문반 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/268.png';
skillImage['귀백 해방'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/528.png';
skillImage['귀설화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/006.png';
skillImage['귀설화 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/110.png';
skillImage['귀신보'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/578.png';
skillImage['귀신보 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/608.png';
skillImage['귀연참'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/576.png';
skillImage['귀연참 : 극'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/596.png';
skillImage['귀연참 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/606.png';
skillImage['귀영섬'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/138.png';
skillImage['귀영섬 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/314.png';
skillImage['귀인(鬼人)'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/574.png';
skillImage['귀참'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/010.png';
skillImage['귀참 : 광폭'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/530.png';
skillImage['귀참 : 광폭 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/538.png';
skillImage['귀참 : 나락'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/536.png';
skillImage['귀참 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/226.png';
skillImage['귀참 드라이브'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/046.png';
skillImage['귀참:나락 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/552.png';
skillImage['귀천살(鬼天殺)'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/630.png';
skillImage['귀혼일체'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/592.png';
skillImage['귀혼환보'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/582.png';
skillImage['그라운드 씨커'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/124.png';
skillImage['그라운드 킥'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/248.png';
skillImage['그라운드 킥 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/272.png';
skillImage['그라운드 태클'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/026.png';
skillImage['그라운드 태클 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/180.png';
skillImage['그래플러의 경갑 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/1/126.png';
skillImage['그래플링 테크닉'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/358.png';
skillImage['그랜드 웨이브'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/392.png';
skillImage['그랜드 웨이브 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/476.png';
skillImage['그랜드 크로스 크래쉬'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/108.png';
skillImage['그랜드 크로스 크래쉬 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/132.png';
skillImage['그랜드 피날레'] = '//bbscdn.df.nexon.com/data6/commu/202102/135802_6018dbdac0662.png';
skillImage['그랩 캐넌'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/122.png';
skillImage['그랩캐넌'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/096.png';
skillImage['그리드 더 러커'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/040.png';
skillImage['그리드 더 러커 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/064.png';
skillImage['그림자 유도분신'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/289.png';
skillImage['그림자 이동'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/297.png';
skillImage['그물 투척'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/044.png';
skillImage['극 귀검술 : 시공섬'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/070.png';
skillImage['극 귀검술 : 심검'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/424.png';
skillImage['극 귀검술 : 유성락'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/416.png';
skillImage['극 귀검술 : 유성락 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/544.png';
skillImage['극 귀검술 : 참철식'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/200.png';
skillImage['극 귀검술 : 파검무'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/355.png';
skillImage['극 귀검술 : 폭풍식'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/192.png';
skillImage['극 마검술 : 창륜검'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/417.png';
skillImage['극 발검술 : 무형참'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/685.png';
skillImage['극 발검술 : 섬단'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/522.png';
skillImage['극검'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/224.png';
skillImage['극검 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/290.png';
skillImage['극진 : 패황단공권'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/372.png';
skillImage['극초발도'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/212.png';
skillImage['극초발도 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/546.png';
skillImage['극파동검 빙인'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/246.png';
skillImage['극파동검 빙인 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/550.png';
skillImage['극파동검 폭염'] = 'https://dundam.xyz/img/skill/695.png';
skillImage['블랙 루나틱'] = 'https://dundam.xyz/img/skill/850.png';
skillImage['블랙 루나틱 시전'] = 'https://dundam.xyz/img/skill/850.png';
skillImage['블랙 루나틱 피니쉬'] = 'https://dundam.xyz/img/skill/850.png';
skillImage['극파동검 폭염 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/548.png';
skillImage['극한의 경지'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/428.png';
skillImage['글라키에스'] = 'https://bbscdn.df.nexon.com/data6/commu/202011/102732_5fbdb30470385.png';
skillImage['글러트니 바밋'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/244.png';
skillImage['글러트니 바밋 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/312.png';
skillImage['글로리 오브 더 라이트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/252.png';
skillImage['금강쇄'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/1/192.png';
skillImage['금강쇄 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/1/264.png';
skillImage['금기 : 독사굴'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/370.png';
skillImage['금나장'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/004.png';
skillImage['금나장 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/108.png';
skillImage['금뇌호 : 심판의 넨수'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/018.png';
skillImage['금단의 저주'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/719.png';
skillImage['급소 지정'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/082.png';
skillImage['기간틱 타이푼'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/136.png';
skillImage['기공장'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/030.png';
skillImage['기공장 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/222.png';
skillImage['기공환'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/128.png';
skillImage['기공환 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/304.png';
skillImage['기다려!!'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/566.png';
skillImage['기본기 숙련 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/991.png';
skillImage['기습타격'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/234.png';
skillImage['기습타격 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/236.png';
skillImage['기옥탄'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/352.png';
skillImage['기옥탄 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/360.png';
skillImage['기요틴'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/074.png';
skillImage['기요틴 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/176.png';
skillImage['기초 방어구 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/990.png';
skillImage['기호지세'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/016.png';
skillImage['기호지세 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/184.png';
skillImage['길로틴'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/032.png';
skillImage['길로틴 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/056.png';
skillImage['꼼꼼한 바느질'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/700.png';
skillImage['꼼꼼한 바느질 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/737.png';
skillImage['끈끈이 투척'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/478.png';
skillImage['나락 떨구기'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/299.png';
skillImage['나락 떨구기 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/321.png';
skillImage['나를 따라라'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/048.png';
skillImage['나선 대차륜 쿠나이'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/244.png';
skillImage['나선 대차륜 쿠나이 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/270.png';
skillImage['나선의 넨'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/088.png';
skillImage['나선의 넨 : 주'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/108.png';
skillImage['나선의 넨 : 주 180히트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/108.png';
skillImage['나선의 넨 : 주 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/186.png';
skillImage['나선의 넨 : 출'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/104.png';
skillImage['나선의 넨 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/224.png';
skillImage['나선흡기'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/256.png';
skillImage['나의 공격리듬은!!'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/058.png';
skillImage['나이트 할로우'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/184.png';
skillImage['나이트 할로우 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/374.png';
skillImage['나이트폴'] = '//bbscdn.df.nexon.com/data6/commu/202106/173544_60dc2ce0c07e4.png';
skillImage['낙뢰부'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/122.png';
skillImage['낙뢰부 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/348.png';
skillImage['낙봉추'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/008.png';
skillImage['낙봉추 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/238.png';
skillImage['낙화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/266.png';
skillImage['낙화장'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/008.png';
skillImage['낙화장 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/354.png';
skillImage['난격'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/138.png';
skillImage['난격 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/350.png';
skillImage['난사'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/022.png';
skillImage['난사 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/316.png';
skillImage['난화검'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/200.png';
skillImage['난화검 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/292.png';
skillImage['날개 꿰기'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/234.png';
skillImage['날개 꿰기 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/236.png';
skillImage['날다람쥐'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/046.png';
skillImage['날다람쥐 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/178.png';
skillImage['날벼락부'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/134.png';
skillImage['날벼락부 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/144.png';
skillImage['날카로운 단검'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/291.png';
skillImage['납도'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/042.png';
skillImage['낫 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/294.png';
skillImage['내딛는 한걸음'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/999/icon/118.png';
skillImage['냉기'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/10/icon/466.png';
skillImage['냉기의 사야'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/082.png';
skillImage['냉기의 사야 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/316.png';
skillImage['냉정함'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/238.png';
skillImage['냉혈'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/140.png';
skillImage['너클 블래스터'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/284.png';
skillImage['너클 블래스터 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/290.png';
skillImage['네버모어'] = '//bbscdn.df.nexon.com/data6/commu/202103/133019_6050345b2eba3.png';
skillImage['네이팜 탄'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/138.png';
skillImage['네이팜 탄 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/318.png';
skillImage['넥 디바이더'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/283.png';
skillImage['넥 디바이더 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/317.png';
skillImage['넥 스냅'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/1/168.png';
skillImage['넥 스냅 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/190.png';
skillImage['넥 업 사이드'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/022.png';
skillImage['넥 업 사이드 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/048.png';
skillImage['넨 가드'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/090.png';
skillImage['넨 스피어'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/160.png';
skillImage['넨 스피어 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/302.png';
skillImage['넨가드 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/278.png';
skillImage['넨마스터의 천 갑옷 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/204.png';
skillImage['넨으로 느낀다'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/084.png';
skillImage['넨의 극의'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/110.png';
skillImage['넨탄'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/1/028.png';
skillImage['넨탄 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/1/226.png';
skillImage['넨화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/152.png';
skillImage['노바 램넌트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/331.png';
skillImage['뇌격'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/120.png';
skillImage['뇌격 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/194.png';
skillImage['뇌격점혈섬'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/014.png';
skillImage['뇌격점혈섬 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/094.png';
skillImage['뇌룡격멸 : 포효'] = '//bbscdn.df.nexon.com/data6/commu/202102/140514_6018dd8a9550e.png';
skillImage['뇌명 : 사나운 빛의 넨수'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/166.png';
skillImage['뇌명 : 사나운 빛의 넨수 평타'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/166.png';
skillImage['뇌신'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/436.png';
skillImage['뇌신의 기운'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/442.png';
skillImage['뇌신의 기운 10히트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/442.png';
skillImage['뇌연격'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/188.png';
skillImage['뇌연격 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/376.png';
skillImage['뇌전각'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/277.png';
skillImage['뇌전각 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/279.png';
skillImage['니들 소배트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/226.png';
skillImage['니들 소배트 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/332.png';
skillImage['니들 스핀'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/1/162.png';
skillImage['니들 스핀 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/1/260.png';
skillImage['니들러'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/226.png';
skillImage['니들러 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/228.png';
skillImage['니우의 전투술'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/102.png';
skillImage['니콜라스 강령'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/064.png';
skillImage['니콜라스 강령 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/180.png';
skillImage['니트로 모터'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/496.png';
skillImage['다이빙 애로우'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/006.png';
skillImage['다이빙 애로우 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/148.png';
skillImage['다이어 스트림'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/528.png';
skillImage['다이하드'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/076.png';
skillImage['다이하드 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/228.png';
skillImage['다크 디비젼'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/264.png';
skillImage['다크 디비젼 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/304.png';
skillImage['다크 랜서의 가죽 갑옷 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/196.png';
skillImage['다크 러스트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/374.png';
skillImage['다크 러스트 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/464.png';
skillImage['다크 레이브'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/378.png';
skillImage['다크 레이브 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/468.png';
skillImage['다크 버스트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/504.png';
skillImage['다크 베리얼'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/250.png';
skillImage['다크 베리얼 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/252.png';
skillImage['다크 볼'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/404.png';
skillImage['다크 볼 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/486.png';
skillImage['다크 브레이크'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/380.png';
skillImage['다크 브레이크 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/470.png';
skillImage['다크 소드'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/376.png';
skillImage['다크 소드 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/466.png';
skillImage['다크 소울'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/036.png';
skillImage['다크 소울 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/172.png';
skillImage['다크 스타'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/256.png';
skillImage['다크 스타 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/270.png';
skillImage['다크 슬래쉬'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/406.png';
skillImage['다크 슬래쉬 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/488.png';
skillImage['다크 엠비션'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/206.png';
skillImage['다크 엠비션 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/208.png';
skillImage['다크 웨이브'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/344.png';
skillImage['다크 웨이브 폴'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/508.png';
skillImage['다크 웨이브 폴 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/556.png';
skillImage['다크 익스플로젼'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/398.png';
skillImage['다크 익스플로젼 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/480.png';
skillImage['다크 임펄스'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/280.png';
skillImage['다크 크래셔'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/394.png';
skillImage['다크 크로스'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/348.png';
skillImage['다크 크로스 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/446.png';
skillImage['다크 폴'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/396.png';
skillImage['다크 폴 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/478.png';
skillImage['다크 플레임 소드'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/410.png';
skillImage['다크 플레임 소드 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/496.png';
skillImage['다크 하울링'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/463.png';
skillImage['다크니스'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/240.png';
skillImage['다크니스 멘틀'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/026.png';
skillImage['다크니스 멘틀 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/138.png';
skillImage['다크문'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/512.png';
skillImage['다크템플러의 판금 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/236.png';
skillImage['단검 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/014.png';
skillImage['단검투척'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/311.png';
skillImage['단검투척 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/430.png';
skillImage['단공참'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/018.png';
skillImage['단공참 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/230.png';
skillImage['단죄의 십자가'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/160.png';
skillImage['단죄의 십자가 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/200.png';
skillImage['단층지격'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/258.png';
skillImage['단층지격 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/352.png';
skillImage['달빛 가르기'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/018.png';
skillImage['달빛 가르기 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/076.png';
skillImage['달빛 베기'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/160.png';
skillImage['달빛 베기 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/266.png';
skillImage['달의 커튼'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/062.png';
skillImage['대검 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/036.png';
skillImage['대난투'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/209.png';
skillImage['대시 평타'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/991.png';
skillImage['대시 평타 10회'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/991.png';
skillImage['대식가'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/280.png';
skillImage['대왕 파리채'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/284.png';
skillImage['대왕 파리채 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/674.png';
skillImage['대자연의 가호'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/056.png';
skillImage['대진공'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/364.png';
skillImage['대진공 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/366.png';
skillImage['대천사의 축복'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/266.png';
skillImage['대천사의 축복 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/320.png';
skillImage['대회전격'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/114.png';
skillImage['대회전격 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/274.png';
skillImage['더 게이트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/516.png';
skillImage['더 나인 배럴'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/134.png';
skillImage['더 라스트 리벨레이션'] = '//bbscdn.df.nexon.com/data6/commu/202102/140159_6018dcc71bb5e.png';
skillImage['더블 건호크'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/184.png';
skillImage['더블 건호크 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/372.png';
skillImage['더블 배럴'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/112.png';
skillImage['더블 배럴 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/114.png';
skillImage['더블 섀도우'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/066.png';
skillImage['더블 스윙'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/342.png';
skillImage['더블 스윙 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/380.png';
skillImage['더블 쓰로우'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/202.png';
skillImage['더블 쓰로우 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/204.png';
skillImage['더블 피어스'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/018.png';
skillImage['더블 피어스 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/182.png';
skillImage['더스트 샷'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/02/icon/618.png';
skillImage['더스트 샷 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/02/icon/644.png';
skillImage['더킹 대시'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/086.png';
skillImage['더킹 바디블로'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/074.png';
skillImage['더킹 스트레이트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/088.png';
skillImage['더킹 어택 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/435.png';
skillImage['더킹 어퍼'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/072.png';
skillImage['더티 배럴'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/254.png';
skillImage['더티 배럴 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/310.png';
skillImage['데드 식스'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/178.png';
skillImage['데드 식스 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/180.png';
skillImage['데드 핸즈'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/312.png';
skillImage['데드리프트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/152.png';
skillImage['데드리프트 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/154.png';
skillImage['데들리 매서커'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/136.png';
skillImage['데들리 어프로치'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/518.png';
skillImage['데들리 어프로치 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/578.png';
skillImage['데들리 케이프'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/188.png';
skillImage['데들리 케이프 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/270.png';
skillImage['데모닉 인페르노'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/140.png';
skillImage['데모닉 크라운'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/658.png';
skillImage['데몬 배리어'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/304.png';
skillImage['데몬 슬레이어의 중갑 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/080.png';
skillImage['데몰리션 펀치'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/445.png';
skillImage['데몰리션 펀치 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/498.png';
skillImage['데보닝 슬래셔'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/288.png';
skillImage['데빌 스트라이커'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/322.png';
skillImage['데빌 클로 18회'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/322.png';
skillImage['데빌 스트라이커 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/342.png';
skillImage['데빌 클러치'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/410.png';
skillImage['데빌 클러치 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/469.png';
skillImage['데빌 피스트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/512.png';
skillImage['데빌 피스트 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/514.png';
skillImage['데스 바이 리볼버'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/146.png';
skillImage['데스 사이드'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/290.png';
skillImage['데스 사이드 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/336.png';
skillImage['데스 차지'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/290.png';
skillImage['데스 크라이시스'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/02/icon/624.png';
skillImage['데스 허리케인'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/222.png';
skillImage['데스블로우'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/433.png';
skillImage['데스티니 퍼펫'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/723.png';
skillImage['데스티니 퍼펫 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/749.png';
skillImage['데우스 엑스 레이'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/331.png';
skillImage['데이브레이크'] = '//bbscdn.df.nexon.com/data6/commu/202106/175003_60dc303b45c3e.png';
skillImage['데인저 클로즈'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/456.png';
skillImage['데인저러스 훅'] = 'https://bbscdn.df.nexon.com/data6/commu/202106/173348_60dc2c6c98ab5.png';
skillImage['데피니트 솔루션'] = 'https://bbscdn.df.nexon.com/data6/commu/202101/105020_5ff3c5dca6fbd.png';
skillImage['도 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/034.png';
skillImage['도그마 디바이스'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/246.png';
skillImage['도발'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/1/076.png';
skillImage['도발 인형 : 슈르르'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/158.png';
skillImage['도발 인형 : 슈르르 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/350.png';
skillImage['독 바르기'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/072.png';
skillImage['독무'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/140.png';
skillImage['독문마경'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/404.png';
skillImage['독문무공'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/340.png';
skillImage['독문무공 1~4타'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/340.png';
skillImage['독문무공 독사굴'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/370.png';
skillImage['독병 투척'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/036.png';
skillImage['독병 투척 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/196.png';
skillImage['독에 익숙함'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/1/068.png';
skillImage['두꺼비유염탄'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/258.png';
skillImage['두꺼비유염탄 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/276.png';
skillImage['두더지 사냥'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/222.png';
skillImage['두더지 사냥 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/224.png';
skillImage['둔기 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/040.png';
skillImage['둠 글레이브'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/044.png';
skillImage['둠 글레이브 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/298.png';
skillImage['둠스데이'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/230.png';
skillImage['뒷골목 싸움법'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/1/074.png';
skillImage['뒷목 가격'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/022.png';
skillImage['뒷목 가격 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/080.png';
skillImage['뒷주머니'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/232.png';
skillImage['듀얼 슬래쉬'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/028.png';
skillImage['듀얼 슬래쉬 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/052.png';
skillImage['듀얼 트리거'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/560.png';
skillImage['듀얼 플리커'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/168.png';
skillImage['듀얼리스트의 경갑 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/134.png';
skillImage['드라이아웃'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/180.png';
skillImage['드래고니안랜서의 중갑 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/158.png';
skillImage['드래곤 브레스'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/278.png';
skillImage['드래곤 스매쉬'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/282.png';
skillImage['드래곤 스매쉬 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/288.png';
skillImage['드래곤 슬레이어'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/178.png';
skillImage['드래곤 윙'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/256.png';
skillImage['드래곤 팽'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/286.png';
skillImage['드래곤 팽 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/292.png';
skillImage['드래곤스케일 경갑 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/308.png';
skillImage['드래곤투스'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/247.png';
skillImage['드래곤포스'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/330.png';
skillImage['드레드보어'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/036.png';
skillImage['드레드보어 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/106.png';
skillImage['드로우'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/10/icon/456.png';
skillImage['드로우 소드'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/368.png';
skillImage['드로우 소드 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/458.png';
skillImage['들끓는 피'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/498.png';
skillImage['등봉조극'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/345.png';
skillImage['디 엔드 오브 타임'] = '//bbscdn.df.nexon.com/data6/commu/202105/114946_6099f0cac91f8.png';
skillImage['디-데이'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/468.png';
skillImage['디멘션 몰트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/072.png';
skillImage['디멘션워커의 천 갑옷 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/236.png';
skillImage['디바이드 어쓰'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/220.png';
skillImage['디바이드 어쓰 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/222.png';
skillImage['디바인 생츄어리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/318.png';
skillImage['디바인 실드'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/312.png';
skillImage['디바인 크러시'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/172.png';
skillImage['디바인 크러시 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/356.png';
skillImage['디바인 퍼니쉬먼트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/421.png';
skillImage['디바인 플래쉬'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/415.png';
skillImage['디베스테이트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/040.png';
skillImage['디베스테이트 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/110.png';
skillImage['디사이시브 배틀'] = '//bbscdn.df.nexon.com/data6/commu/202101/104837_5ff3c575b3acf.png';
skillImage['디스럽션'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/338.png';
skillImage['디스럽션 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/340.png';
skillImage['디스인챈트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/098.png';
skillImage['디스트러블 퀘이크'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/062.png';
skillImage['디스트러블 퀘이크 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/108.png';
skillImage['디스트로이어'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/190.png';
skillImage['디스트로이어 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/272.png';
skillImage['디스트리뷰션'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/400.png';
skillImage['디스펄션'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/282.png';
skillImage['디스펄션 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/312.png';
skillImage['디스페어'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/246.png';
skillImage['디스페어 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/248.png';
skillImage['디아볼릭 슬래쉬'] = '//bbscdn.df.nexon.com/data6/commu/202105/114927_6099f0b7c13c3.png';
skillImage['디퓨전 버스트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/050.png';
skillImage['디퓨전 버스트 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/098.png';
skillImage['디퓨젼'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/214.png';
skillImage['디퓨젼 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/216.png';
skillImage['디플렉트 월'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/146.png';
skillImage['디플렉트 월 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/358.png';
skillImage['딥 더스크'] = '//bbscdn.df.nexon.com/data6/commu/202106/173505_60dc2cb917dae.png';
skillImage['라스트 오더'] = '//bbscdn.df.nexon.com/data6/commu/202106/173555_60dc2cebab515.png';
skillImage['라스트 제네시스'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/284.png';
skillImage['라우스 디 안젤루스'] = 'https://bbscdn.df.nexon.com/data6/commu/202009/175612_5f6081ac6aec7.png';
skillImage['라이온 킥'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/008.png';
skillImage['라이온 킥 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/103.png';
skillImage['라이징 너클'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/174.png';
skillImage['라이징 너클 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/280.png';
skillImage['라이징 랜서'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/060.png';
skillImage['라이징 슬래쉬'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/152.png';
skillImage['라이징 슬래쉬 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/194.png';
skillImage['라이징 컷'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/000.png';
skillImage['라이징 컷 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/184.png';
skillImage['라이징샷'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/118.png';
skillImage['라이징샷 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/352.png';
skillImage['라이트 브랜디쉬'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/284.png';
skillImage['라이트닝 댄스'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/146.png';
skillImage['라이트닝 댄스 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/250.png';
skillImage['라이트닝 슬래쉬'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/208.png';
skillImage['라이트닝 슬래쉬 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/210.png';
skillImage['라이트닝 시프트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/412.png';
skillImage['라이트닝 애로우'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/030.png';
skillImage['라이트닝 애로우 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/186.png';
skillImage['라이트닝 월'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/044.png';
skillImage['라이트닝 월 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/136.png';
skillImage['라케르쏜즈'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/399.png';
skillImage['라파엘의 축복'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/286.png';
skillImage['래피드 무브'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/366.png';
skillImage['래피드 무브 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/456.png';
skillImage['랜턴 파이어'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/090.png';
skillImage['랜턴 파이어 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/474.png';
skillImage['램 펜스'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/168.png';
skillImage['램 펜스 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/188.png';
skillImage['램펀트 매드니스'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/666.png';
skillImage['러스트 핸드'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/030.png';
skillImage['러스트 핸드 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/054.png';
skillImage['러스티 네일'] = '//bbscdn.df.nexon.com/data6/commu/202106/173241_60dc2c298cde0.png';
skillImage['럭키 스트레이트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/016.png';
skillImage['럭키 스트레이트 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/242.png';
skillImage['런지 스트라이크'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/042.png';
skillImage['런지 스트라이크 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/296.png';
skillImage['런지 에볼루션'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/070.png';
skillImage['런지 에볼루션 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/104.png';
skillImage['런처의 중갑 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/250.png';
skillImage['럼블독'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/360.png';
skillImage['럼블독 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/430.png';
skillImage['레쓰'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/221.png';
skillImage['레쓰 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/298.png';
skillImage['레이 오브 프리미티브'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/804.png';
skillImage['레이저 라이플'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/018.png';
skillImage['레이저 라이플 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/362.png';
skillImage['레이징 퓨리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/048.png';
skillImage['레이징 퓨리 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/286.png';
skillImage['레인저의 가죽 갑옷 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/152.png';
skillImage['레조넌스 임페일'] = '//bbscdn.df.nexon.com/data6/commu/202103/133615_605035bf2da7c.png';
skillImage['레쳐스 데빌라이즈'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/526.png';
skillImage['레트리뷰션'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/223.png';
skillImage['레트리뷰션 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/300.png';
skillImage['로그의 가죽 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/128.png';
skillImage['로드 오브 템페스트 : 아라칸'] = 'https://bbscdn.df.nexon.com/data6/commu/202011/102926_5fbdb37633408.png';
skillImage['로드 투 헬'] = 'https://bbscdn.df.nexon.com/data6/commu/202002/153411_5e39106336d24.png';
skillImage['로망 대시'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/010.png';
skillImage['로망 대시 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/068.png';
skillImage['로보틱스'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/116.png';
skillImage['로봇 엔지니어링'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/614.png';
skillImage['로봇 전폭'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/128.png';
skillImage['로즈 바인'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/692.png';
skillImage['로즈 바인 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/735.png';
skillImage['로킥'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/1/006.png';
skillImage['로킥 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/166.png';
skillImage['로테이션 웨이브'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/188.png';
skillImage['로테이션 웨이브 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/190.png';
skillImage['로플링'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/050.png';
skillImage['로플링 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/198.png';
skillImage['록 온 서포트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/182.png';
skillImage['록 온 서포트 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/396.png';
skillImage['롤리팝 크러쉬'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/518.png';
skillImage['롤링 러쉬'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/036.png';
skillImage['롤링 러쉬 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/060.png';
skillImage['롤링 무브'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/046.png';
skillImage['롤링 벌컨'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/020.png';
skillImage['롤링 벌컨 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/292.png';
skillImage['롤링 스파이크'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/054.png';
skillImage['롤링 스파이크 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/138.png';
skillImage['루이네이션 에어리어'] = '//bbscdn.df.nexon.com/data6/commu/202101/104728_5ff3c530067d4.png';
skillImage['룰 브레이크'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/234.png';
skillImage['류심'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/250.png';
skillImage['류심 강'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/260.png';
skillImage['류심 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/320.png';
skillImage['류심 승'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/258.png';
skillImage['류심 충'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/254.png';
skillImage['류심 쾌'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/256.png';
skillImage['류탄 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/312.png';
skillImage['리 귀검술'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/148.png';
skillImage['리미트 브레이크'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/332.png';
skillImage['리버레이트 - 매드니스'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/317.png';
skillImage['리버레이트 - 매드니스 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/323.png';
skillImage['리버레이트 - 벅샷'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/182.png';
skillImage['리버레이트 - 벅샷 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/264.png';
skillImage['리벤저'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/032.png';
skillImage['리벤저 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/374.png';
skillImage['리볼버 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/434.png';
skillImage['리빙데드'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/333.png';
skillImage['리빙데드 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/369.png';
skillImage['리인포스 파이어'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/124.png';
skillImage['리전 오브 슈프림'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/419.png';
skillImage['리탈 펀토'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/012.png';
skillImage['리탈 펀토 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/091.png';
skillImage['리펜텐스 스매시'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/264.png';
skillImage['리펜텐스 스매시 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/318.png';
skillImage['릴로이'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/320.png';
skillImage['릴로이 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/322.png';
skillImage['릴리악'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/308.png';
skillImage['릴리악 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/412.png';
skillImage['링크'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/10/icon/544.png';
skillImage['링크 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/10/icon/682.png';
skillImage['마검 제어'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/052.png';
skillImage['마검발현'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/046.png';
skillImage['마검발현 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/138.png';
skillImage['마검방출'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/353.png';
skillImage['마검일체'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/421.png';
skillImage['마그네틱 필드'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/590.png';
skillImage['마나 익스트랙트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/164.png';
skillImage['마나 폭주'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/080.png';
skillImage['마도학자의 가죽 갑옷 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/256.png';
skillImage['마력 증폭'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/638.png';
skillImage['마리오네트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/757.png';
skillImage['마릴린 로즈'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/228.png';
skillImage['마릴린 로즈 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/354.png';
skillImage['마운트(여)'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/022.png';
skillImage['마운트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/032.png';
skillImage['크레이지 발칸(여)'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/158.png';
skillImage['마이크로 유니버스'] = 'https://bbscdn.df.nexon.com/data6/commu/202011/102550_5fbdb29e81809.png';
skillImage['마인의 검세'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/335.png';
skillImage['마인의 여왕'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/409.png';
skillImage['마지막 임무'] = '//bbscdn.df.nexon.com/data6/commu/202104/141007_607527af9107d.png';
skillImage['마창 각성'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/142.png';
skillImage['마창 제어'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/076.png';
skillImage['마창 지배'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/078.png';
skillImage['마창 폭주'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/314.png';
skillImage['마창 해방'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/074.png';
skillImage['마하킥'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/008.png';
skillImage['마하킥 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/314.png';
skillImage['마혼부 : 퇴마음양옥'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/296.png';
skillImage['마혼제령술'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/180.png';
skillImage['마혼제령술 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/248.png';
skillImage['마환 풍마수리검'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/280.png';
skillImage['마환 풍마수리검 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/282.png';
skillImage['만병지왕 - 파진포'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/314.png';
skillImage['만월 달빛 베기'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/174.png';
skillImage['만천화우'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/238.png';
skillImage['망상의 불꽃'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/380.png';
skillImage['망자의 원한'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/052.png';
skillImage['망자의 원한 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/188.png';
skillImage['망자의 집념'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/144.png';
skillImage['망자의 집념 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/426.png';
skillImage['매거진 드럼'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/126.png';
skillImage['매그넘설트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/292.png';
skillImage['매그넘설트 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/294.png';
skillImage['매드 더 빅'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/729.png';
skillImage['매드 더 빅 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/753.png';
skillImage['매드 스트레이트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/706.png';
skillImage['매드 스트레이트 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/741.png';
skillImage['매드매드 슬래쉬'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/702.png';
skillImage['매드매드 슬래쉬 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/739.png';
skillImage['매디가드'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/717.png';
skillImage['매디가드 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/777.png';
skillImage['매스 텔레포트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/504.png';
skillImage['매시브 파이어'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/405.png';
skillImage['매지컬 체인지'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/660.png';
skillImage['매직 미사일'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/000.png';
skillImage['매직 미사일 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/384.png';
skillImage['맹독 일발화약성'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/220.png';
skillImage['맹독 일발화약성 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/390.png';
skillImage['맹룡단공참'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/162.png';
skillImage['맹룡단공참 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/248.png';
skillImage['머로더'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/382.png';
skillImage['머로더 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/432.png';
skillImage['머슬 시프트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/182.png';
skillImage['머신건 잽'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/176.png';
skillImage['머신건 잽 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/360.png';
skillImage['멀티 헤드샷'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/186.png';
skillImage['멀티 헤드샷 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/376.png';
skillImage['메가 드릴'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/270.png';
skillImage['메가 드릴 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/386.png';
skillImage['메나싱 시즈'] = 'https://bbscdn.df.nexon.com/data6/commu/202010/151546_5f97bb12b519d.png';
skillImage['메모라이즈'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/052.png';
skillImage['메조싸이클론'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/008.png';
skillImage['메조싸이클론 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/090.png';
skillImage['메카 드롭'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/130.png';
skillImage['메카 드롭 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/388.png';
skillImage['메카닉의 천 갑옷 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/248.png';
skillImage['메타몰포시스'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/457.png';
skillImage['멘데이트 오브 헤븐'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/322.png';
skillImage['멘데이트 오브 헤븐 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/421.png';
skillImage['멸!'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/404.png';
skillImage['멸광천투'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/282.png';
skillImage['멸극 : 흑현금절'] = '//bbscdn.df.nexon.com/data6/commu/202103/133425_60503551e03d0.png';
skillImage['멸혼부'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/408.png';
skillImage['멸혼부 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/504.png';
skillImage['멸화파천격'] = 'https://bbscdn.df.nexon.com/data6/commu/202002/152829_5e390f0d0dec9.png';
skillImage['모멘터리 슬래쉬'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/352.png';
skillImage['모멘터리 슬래쉬 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/448.png';
skillImage['목표 사격'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/032.png';
skillImage['목표 사격 1탄창'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/032.png';
skillImage['목표 사격 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/090.png';
skillImage['목표 포착'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/034.png';
skillImage['목표 포착 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/298.png';
skillImage['목환자경'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/138.png';
skillImage['목환자경 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/148.png';
skillImage['몽롱한 눈의 브레멘'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/084.png';
skillImage['몽롱한 눈의 브레멘 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/232.png';
skillImage['무간연옥 : 속죄의 제단'] = 'https://bbscdn.df.nexon.com/data6/commu/202009/175705_5f6081e1544d7.png';
skillImage['무거운 죄업'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/020.png';
skillImage['무거운 죄업 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/046.png';
skillImage['무기 막기'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/156.png';
skillImage['무기 막기 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/198.png';
skillImage['무기의 극의'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/066.png';
skillImage['무녀의 천옷 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/014.png';
skillImage['무릎 찍기'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/000.png';
skillImage['무릎 찍기 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/168.png';
skillImage['무식 : 신귀극참'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/672.png';
skillImage['무쌍격'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/190.png';
skillImage['무쌍격 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/362.png';
skillImage['무쌍파'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/144.png';
skillImage['무쌍파 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/288.png';
skillImage['무영 : 참극'] = 'https://bbscdn.df.nexon.com/data6/commu/202010/151609_5f97bb29e255d.png';
skillImage['무영각'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/374.png';
skillImage['무자비'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/664.png';
skillImage['무장 해제'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/018.png';
skillImage['무장 해제 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/105.png';
skillImage['무즈 어퍼'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/008.png';
skillImage['무즈 어퍼 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/284.png';
skillImage['무형의 창술사'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/150.png';
skillImage['무형지경'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/683.png';
skillImage['묵상'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/490.png';
skillImage['문무겸비'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/526.png';
skillImage['문아크'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/122.png';
skillImage['문페이즈'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/118.png';
skillImage['문페이즈 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/300.png';
skillImage['물대포'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/082.png';
skillImage['물대포 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/112.png';
skillImage['미드나잇 카니발'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/403.png';
skillImage['미드나잇 카니발 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/417.png';
skillImage['미라지 부스트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/308.png';
skillImage['미라지 스탠스'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/058.png';
skillImage['미라지 스트림'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/016.png';
skillImage['미라지 어택 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/086.png';
skillImage['미라클 비전'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/150.png';
skillImage['미라클 샤인'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/288.png';
skillImage['미러클 스플리터'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/486.png';
skillImage['미러클 스플리터 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/500.png';
skillImage['미스트 플레어'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/144.png';
skillImage['미스트리스의 중갑 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/016.png';
skillImage['미티어 버스트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/342.png';
skillImage['미티어 버스트 단독'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/342.png';
skillImage['바늘 투척'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/040.png';
skillImage['바늘 투척 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/200.png';
skillImage['바람'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/10/icon/470.png';
skillImage['바람의 주인'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/200.png';
skillImage['바베~큐'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/046.png';
skillImage['바베~큐 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/294.png';
skillImage['바운스 블로우'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/360.png';
skillImage['바이올런트 스매쉬'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/132.png';
skillImage['바이올런트 스톰'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/394.png';
skillImage['바이올런트 피어스'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/260.png';
skillImage['바이올런트 피어스 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/306.png';
skillImage['바이퍼 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/390.png';
skillImage['반격'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/114.png';
skillImage['반격 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/088.png';
skillImage['반드시 잡는다!'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/1/114.png';
skillImage['반월'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/026.png';
skillImage['반월 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/116.png';
skillImage['반중력 기동장치'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/254.png';
skillImage['반중력 기동장치 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/348.png';
skillImage['발검술'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/066.png';
skillImage['발도'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/008.png';
skillImage['발도 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/272.png';
skillImage['발라크르 현신'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/335.png';
skillImage['발라크르의 맹약'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/367.png';
skillImage['발라크르의 야망'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/060.png';
skillImage['발라크르의 야망 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/190.png';
skillImage['발목 타격'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/048.png';
skillImage['발목 타격 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/192.png';
skillImage['발현'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/084.png';
skillImage['방검술'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/016.png';
skillImage['방검술 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/136.png';
skillImage['방어 태세'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/022.png';
skillImage['방패 방어'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/010.png';
skillImage['방해'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/10/icon/468.png';
skillImage['배드 엔딩'] = 'https://bbscdn.df.nexon.com/data6/commu/202002/153508_5e39109c72a20.png';
skillImage['배틀 그루브'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/226.png';
skillImage['배틀메이지의 가죽 갑옷 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/192.png';
skillImage['배틀액스 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/010.png';
skillImage['배후습격'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/315.png';
skillImage['백보신장'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/248.png';
skillImage['백보신장 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/389.png';
skillImage['백스텝 커터'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/106.png';
skillImage['백팔염주'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/186.png';
skillImage['백팔염주 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/254.png';
skillImage['밴디트컷'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/010.png';
skillImage['밴디트컷 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/194.png';
skillImage['뱅가드 스트랏슈'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/024.png';
skillImage['뱅가드 스트랏슈 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/101.png';
skillImage['뱅가드의 중갑 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/132.png';
skillImage['버서커의 중갑 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/126.png';
skillImage['버스터 빔'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/432.png';
skillImage['버스터 샷'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/348.png';
skillImage['버스터 샷 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/398.png';
skillImage['버스트 슬래쉬'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/138.png';
skillImage['버스트 퓨리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/210.png';
skillImage['버스트 퓨리 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/562.png';
skillImage['버스트 필드'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/278.png';
skillImage['버스트 혼'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/233.png';
skillImage['버스트 혼 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/270.png';
skillImage['버추얼 러쉬'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/02/icon/634.png';
skillImage['버티컬 스파이럴'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/032.png';
skillImage['버티컬 스파이럴 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/196.png';
skillImage['버팔로 폴'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/230.png';
skillImage['버팔로 폴 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/232.png';
skillImage['번 로커스트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/166.png';
skillImage['번 로커스트 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/186.png';
skillImage['번 투 슬래쉬'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/058.png';
skillImage['번 투 슬래쉬 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/104.png';
skillImage['법력 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/006.png';
skillImage['베가본드의 가죽 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/234.png';
skillImage['베놈 마인'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/046.png';
skillImage['베놈 마인(여)'] = 'http://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/164.png';
skillImage['베놈 마인 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/140.png';
skillImage['베니싱 일루젼'] = 'https://bbscdn.df.nexon.com/data6/commu/202010/150941_5f97b9a5b4827.png';
skillImage['베스퍼스'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/327.png';
skillImage['베인 슬래쉬'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/388.png';
skillImage['베인 슬래쉬 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/474.png';
skillImage['베일드 어택'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/343.png';
skillImage['베일드 컷'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/266.png';
skillImage['벽파일섬'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/268.png';
skillImage['변신 해제'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/636.png';
skillImage['변이 파리채'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/242.png';
skillImage['변이 파리채 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/390.png';
skillImage['병기 숙련'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/538.png';
skillImage['병기강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/262.png';
skillImage['보름달 베기'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/028.png';
skillImage['보름달 베기 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/086.png';
skillImage['보이드'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/110.png';
skillImage['보이드 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/330.png';
skillImage['보조무기 장착가능'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/325.png';
skillImage['보호의 징표'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/042.png';
skillImage['보호의 징표 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/437.png';
skillImage['복마전'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/332.png';
skillImage['복마전 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/492.png';
skillImage['복수의 가시'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/300.png';
skillImage['복수의 가시 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/364.png';
skillImage['복종하는 사령'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/070.png';
skillImage['본 실드'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/038.png';
skillImage['본 실드 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/198.png';
skillImage['본 크러셔'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/010.png';
skillImage['본 크러셔 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/288.png';
skillImage['볼텍스 허리케인'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/216.png';
skillImage['봉 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/134.png';
skillImage['봉인'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/535.png';
skillImage['부동명왕진'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/136.png';
skillImage['부동명왕진 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/290.png';
skillImage['부동심'] = 'https://bbscdn.df.nexon.com/data6/commu/202002/153635_5e3910f33cfe0.png';
skillImage['부동주박진'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/190.png';
skillImage['부동주박진 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/256.png';
skillImage['부활'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/106.png';
skillImage['분기암경파'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/164.png';
skillImage['분기암경파 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/428.png';
skillImage['분노의 난타'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/603.png';
skillImage['분노의 난타 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/688.png';
skillImage['분노의 움켜쥠'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/010.png';
skillImage['분노의 움켜쥠 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/244.png';
skillImage['분마검'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/367.png';
skillImage['분마검 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/369.png';
skillImage['분쇄'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/038.png';
skillImage['분쇄 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/092.png';
skillImage['분신'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/1/012.png';
skillImage['분신 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/1/290.png';
skillImage['불길한 눈웃음'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/836.png';
skillImage['불사'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/086.png';
skillImage['불스아이'] = '//bbscdn.df.nexon.com/data6/commu/202106/174331_60dc2eb3781d4.png';
skillImage['불지옥'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/308.png';
skillImage['붉은 달의 사냥제'] = '//bbscdn.df.nexon.com/data6/commu/202103/133244_605034ec8f927.png';
skillImage['붉은 사탕'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/816.png';
skillImage['붕권'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/170.png';
skillImage['붕권 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/292.png';
skillImage['붕산격'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/154.png';
skillImage['붕산격 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/278.png';
skillImage['브랜디쉬'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/030.png';
skillImage['브랜디쉬 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/100.png';
skillImage['브레이크 다운'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/242.png';
skillImage['브레이크 다운 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/252.png';
skillImage['브레이크 샷'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/048.png';
skillImage['브레이크 샷 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/096.png';
skillImage['브레이킹 러시'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/020.png';
skillImage['브레이킹 엘보'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/180.png';
skillImage['브레이킹 엘보 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/294.png';
skillImage['브레인 스톰'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/162.png';
skillImage['브로큰 애로우'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/034.png';
skillImage['브로큰 애로우 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/148.png';
skillImage['브루탈 디몰리셔'] = '//bbscdn.df.nexon.com/data6/commu/202103/133006_6050344e4c04d.png';
skillImage['브리프 컷'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/354.png';
skillImage['블랙 망토'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/230.png';
skillImage['블랙 망토 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/392.png';
skillImage['블랙 미러'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/178.png';
skillImage['블랙 미러 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/268.png';
skillImage['블랙 시드'] = '//bbscdn.df.nexon.com/data6/commu/202104/141629_6075292d43df8.png';
skillImage['블랙 아라크니아'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/365.png';
skillImage['블랙 호라이즌'] = '//bbscdn.df.nexon.com/data6/commu/202104/141645_6075293da1b64.png';
skillImage['블랙아웃'] = 'https://bbscdn.df.nexon.com/data6/commu/202106/173400_60dc2c78a0b72.png';
skillImage['블러드'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/310.png';
skillImage['블러드 리븐'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/432.png';
skillImage['블러드 번'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/328.png';
skillImage['블러드 붐'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/412.png';
skillImage['블러드 소드'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/252.png';
skillImage['블러드 소드 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/294.png';
skillImage['블러드 스내치'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/568.png';
skillImage['블러드 스내치 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/570.png';
skillImage['블러드 스트림'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/388.png';
skillImage['블러드 스틴저'] = 'https://bbscdn.df.nexon.com/data6/commu/202011/102817_5fbdb33150b15.png';
skillImage['블러드 앤 체인'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/490.png';
skillImage['블러드 윙'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/316.png';
skillImage['블러드 윙 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/318.png';
skillImage['블러드 인카넷'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/426.png';
skillImage['블러드 혼'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/438.png';
skillImage['블러드러스트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/098.png';
skillImage['블러드러스트 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/292.png';
skillImage['블러디 레이브'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/176.png';
skillImage['블러디 레이브 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/270.png';
skillImage['블러디 스파이크'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/276.png';
skillImage['블러디 스파이크 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/596.png';
skillImage['블러디 카니발'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/426.png';
skillImage['블럭 투척'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/038.png';
skillImage['블럭 투척 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/296.png';
skillImage['블레이드 스톰'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/038.png';
skillImage['블레이드 스톰 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/108.png';
skillImage['블레이드 팬텀'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/420.png';
skillImage['블레이드 팰컨'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/030.png';
skillImage['블레이드 팰컨 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/088.png';
skillImage['블렌딩 페인'] = '//bbscdn.df.nexon.com/data6/commu/202106/173422_60dc2c8e4dcd7.png';
skillImage['블로우 스트라이크'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/052.png';
skillImage['블로우 스트라이크 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/144.png';
skillImage['블록 버스터'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/130.png';
skillImage['블록 버스터 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/376.png';
skillImage['블록 봄'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/212.png';
skillImage['블록 봄 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/392.png';
skillImage['블룸 파우더'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/622.png';
skillImage['블리자드 스톰'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/042.png';
skillImage['블리자드 스톰 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/160.png';
skillImage['블리츠 크리그'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/260.png';
skillImage['블릿츠 스트라이크'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/235.png';
skillImage['블릿츠 스트라이크 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/272.png';
skillImage['비기 : 염무개화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/260.png';
skillImage['비기 : 염무개화 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/273.png';
skillImage['비기 : 폭독'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/338.png';
skillImage['비룡천상'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/336.png';
skillImage['비룡천상 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/425.png';
skillImage['비룡천상 공중'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/336.png';
skillImage['비룡천상 지상'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/336.png';
skillImage['비상'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/198.png';
skillImage['비상 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/286.png';
skillImage['비연선풍'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/288.png';
skillImage['비연선풍 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/306.png';
skillImage['비연장'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/212.png';
skillImage['비연장 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/294.png';
skillImage['비연참'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/036.png';
skillImage['비연참 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/090.png';
skillImage['비인검 : 알카트라즈'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/413.png';
skillImage['비인외도 : 극'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/339.png';
skillImage['비전 : 진천뢰'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/362.png';
skillImage['비터스 캔디바'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/624.png';
skillImage['비트 드라이브'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/172.png';
skillImage['비트 드라이브 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/296.png';
skillImage['비헤드'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/034.png';
skillImage['비헤드 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/104.png';
skillImage['빅뱅 펀치'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/182.png';
skillImage['빌 드릴'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/182.png';
skillImage['빌 드릴 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/184.png';
skillImage['빔 슬래쉬'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/156.png';
skillImage['빔 슬래쉬 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/158.png';
skillImage['빗자루 다루기'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/238.png';
skillImage['빗자루 스핀'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/228.png';
skillImage['빗자루 스핀 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/394.png';
skillImage['빙결사의 가죽 갑옷 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/090.png';
skillImage['빙류환'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/102.png';
skillImage['빙백검'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/008.png';
skillImage['빙백검 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/114.png';
skillImage['빙하시대'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/10/icon/542.png';
skillImage['빙하시대 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/10/icon/680.png';
skillImage['빛의 복수'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/030.png';
skillImage['빛의 복수 50회'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/030.png';
skillImage['빛의 복수 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/246.png';
skillImage['빛의 은총'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/082.png';
skillImage['빛의 친화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/066.png';
skillImage['뽑아치기'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/076.png';
skillImage['사격술'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/436.png';
skillImage['사공 수련'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/1/070.png';
skillImage['사도의 춤'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/570.png';
skillImage['사도화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/534.png';
skillImage['사랑의 응급조치'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/727.png';
skillImage['사령술사의 경갑 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/130.png';
skillImage['사무치는 광기'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/500.png';
skillImage['사복검 - 무'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/020.png';
skillImage['사복검 - 발'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/000.png';
skillImage['사복검 - 조'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/048.png';
skillImage['사복검 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/315.png';
skillImage['사상경계의 저편'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/242.png';
skillImage['사상최강의 로킥'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/150.png';
skillImage['사슬 해제'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/080.png';
skillImage['사악한 호기심'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/704.png';
skillImage['사영검'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/305.png';
skillImage['사영검 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/307.png';
skillImage['사이드 매서커'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/042.png';
skillImage['사이드 매서커 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/066.png';
skillImage['사이드 스텝'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/026.png';
skillImage['사이드 슬래쉬'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/024.png';
skillImage['사이드 슬래쉬 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/096.png';
skillImage['사이드 와인드'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/174.png';
skillImage['사이드 와인드 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/366.png';
skillImage['사이즈믹 웨이브'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/460.png';
skillImage['사자후'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/026.png';
skillImage['사자후 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/298.png';
skillImage['사형조수'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/056.png';
skillImage['사형조수 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/082.png';
skillImage['사흑주 독아혼천'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/408.png';
skillImage['삭풍'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/204.png';
skillImage['삭풍 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/224.png';
skillImage['살극쌍연무'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/148.png';
skillImage['살기감지'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/164.png';
skillImage['살의의 파동'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/118.png';
skillImage['살의의 파동 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/234.png';
skillImage['삼라만상'] = 'https://bbscdn.df.nexon.com/data6/commu/202002/152709_5e390ebd4342d.png';
skillImage['삼매진화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/218.png';
skillImage['삼일참월'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/022.png';
skillImage['삼일참월 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/294.png';
skillImage['삼재검'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/176.png';
skillImage['삼재회륜검'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/359.png';
skillImage['삼재회륜검 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/361.png';
skillImage['삼화취정'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/363.png';
skillImage['상상력'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/10/icon/642.png';
skillImage['새틀라이트 빔'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/156.png';
skillImage['생명의 원천'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/026.png';
skillImage['생명의 전조'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/052.png';
skillImage['생명의 전조 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/411.png';
skillImage['샤드 매그넘'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/184.png';
skillImage['샤이닝 윙즈 플랩'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/418.png';
skillImage['샤이닝컷'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/004.png';
skillImage['샤이닝컷 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/168.png';
skillImage['샤인 스파크'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/024.png';
skillImage['샤인 크로스'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/268.png';
skillImage['샤인 크로스 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/270.png';
skillImage['섀도우 댄싱'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/303.png';
skillImage['섀도우 댄싱 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/323.png';
skillImage['섀도우 박서'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/064.png';
skillImage['섀도우 스팅'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/313.png';
skillImage['섀도우 스팅 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/432.png';
skillImage['섀도우댄서의 가죽 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/126.png';
skillImage['섀도우컷'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/066.png';
skillImage['섀도우컷 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/200.png';
skillImage['섀터드 글래스'] = '//bbscdn.df.nexon.com/data6/commu/202106/173453_60dc2cad2ed25.png';
skillImage['서드 립'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/282.png';
skillImage['서라운드 브레이크'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/050.png';
skillImage['서라운드 브레이크 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/116.png';
skillImage['서번트 랜스'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/152.png';
skillImage['서번트 랜스 12사출'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/152.png';
skillImage['서번트 랜스 : 어썰트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/218.png';
skillImage['서번트 랜스 : 어썰트 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/220.png';
skillImage['서번트 랜스 : 인게이저'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/170.png';
skillImage['서번트 랜스 : 인게이저 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/190.png';
skillImage['서프라이즈 컷'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/174.png';
skillImage['서프라이즈 컷 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/176.png';
skillImage['서프라이즈 프레전트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/064.png';
skillImage['서프라이즈 프레전트 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/110.png';
skillImage['선고'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/042.png';
skillImage['선고 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/094.png';
skillImage['선혈의 추억'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/198.png';
skillImage['설화연창'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/030.png';
skillImage['설화연창 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/144.png';
skillImage['섬격'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/314.png';
skillImage['섬격 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/316.png';
skillImage['섬광'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/038.png';
skillImage['섬광 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/092.png';
skillImage['섬멸'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/036.png';
skillImage['섬보'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/274.png';
skillImage['성공 예감'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/278.png';
skillImage['성난 불길 가열로'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/266.png';
skillImage['성난 불길 가열로 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/398.png';
skillImage['성령의 메이스'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/488.png';
skillImage['성불'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/184.png';
skillImage['성불 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/280.png';
skillImage['성스러운 분노 : 아스트라페'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/546.png';
skillImage['성창 : 브류나크'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/292.png';
skillImage['성화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/002.png';
skillImage['성화 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/192.png';
skillImage['세라픽 페더'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/217.png';
skillImage['세라픽 폴'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/310.png';
skillImage['세례의 불길'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/172.png';
skillImage['세례의 불길 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/212.png';
skillImage['세례의 빛'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/096.png';
skillImage['세례의 빛 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/124.png';
skillImage['세븐스 플로우'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/452.png';
skillImage['세인트 월'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/098.png';
skillImage['세인트 월 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/126.png';
skillImage['세컨드 소울'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/280.png';
skillImage['세컨드 어퍼'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/012.png';
skillImage['세컨드 어퍼 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/368.png';
skillImage['세컨드 윈드'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/392.png';
skillImage['세큐리스 말레피카룸'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/302.png';
skillImage['셰이커 블래스트'] = '//bbscdn.df.nexon.com/data6/commu/202106/173523_60dc2ccb73903.png';
skillImage['셰이크 다운'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/404.png';
skillImage['소검 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/038.png';
skillImage['소닉 무브'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/202.png';
skillImage['소닉 무브 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/222.png';
skillImage['소닉 스파이크'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/244.png';
skillImage['소닉 스파이크 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/378.png';
skillImage['소닉 어설트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/034.png';
skillImage['소닉 어설트 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/170.png';
skillImage['소드 댄스'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/218.png';
skillImage['소드 댄스 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/220.png';
skillImage['소드 마스터의 경갑 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/078.png';
skillImage['소드 밤'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/052.png';
skillImage['소드 밤 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/100.png';
skillImage['소멸'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/10/icon/650.png';
skillImage['소멸의 의식'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/438.png';
skillImage['소악마'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/755.png';
skillImage['소울 스틸'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/346.png';
skillImage['소울 스틸 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/348.png';
skillImage['소울드레인'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/319.png';
skillImage['소울드레인 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/321.png';
skillImage['소울브링어의 천 갑옷 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/204.png';
skillImage['소태도 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/004.png';
skillImage['소환 해제'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/442.png';
skillImage['소환사의 천 갑옷 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/274.png';
skillImage['속성 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/609.png';
skillImage['속성 발동'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/010.png';
skillImage['속성 발동 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/176.png';
skillImage['속성변환'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/032.png';
skillImage['속성변환 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/134.png';
skillImage['속성의 소검 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/150.png';
skillImage['솔라 모듈 시스템'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/552.png';
skillImage['쇄패'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/006.png';
skillImage['쇄패 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/400.png';
skillImage['쇼다운'] = '//bbscdn.df.nexon.com/data6/commu/202106/174217_60dc2e69d2502.png';
skillImage['쇼크 웨이브'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/390.png';
skillImage['쇼크 웨이브 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/514.png';
skillImage['쇼타임'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/154.png';
skillImage['숄더 태클'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/1/120.png';
skillImage['숄더 태클 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/1/246.png';
skillImage['수라 진공참'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/110.png';
skillImage['수인체'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/088.png';
skillImage['수플렉스'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/000.png';
skillImage['수플렉스 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/234.png';
skillImage['수플렉스 싸이클론'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/184.png';
skillImage['수플렉스 싸이클론 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/262.png';
skillImage['수플렉스 썬더'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/002.png';
skillImage['수플렉스 썬더 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/266.png';
skillImage['수호'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/10/icon/472.png';
skillImage['수호의 은총'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/214.png';
skillImage['수호의 축복'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/086.png';
skillImage['수호자 : 악신 아텐'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/160.png';
skillImage['숙련된 전문가'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/232.png';
skillImage['숙련자의 전투술'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/044.png';
skillImage['순백의 칼날'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/106.png';
skillImage['순백의 칼날 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/370.png';
skillImage['순보'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/002.png';
skillImage['쉐도우 랜서'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/166.png';
skillImage['쉐도우 랜서 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/252.png';
skillImage['쉐도우 바인딩'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/164.png';
skillImage['쉐도우 바인딩 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/254.png';
skillImage['쉐도우 퍼펫'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/170.png';
skillImage['쉐도우 퍼펫 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/266.png';
skillImage['쉐도우 하베스트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/172.png';
skillImage['쉐도우 하베스트 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/256.png';
skillImage['쉐도우 하이딩'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/162.png';
skillImage['쉬카리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/324.png';
skillImage['쉬카리 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/326.png';
skillImage['슈타이어 대전차포'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/052.png';
skillImage['슈타이어 대전차포 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/296.png';
skillImage['슈트롬'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/002.png';
skillImage['슈트롬 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/084.png';
skillImage['슈퍼 노바'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/454.png';
skillImage['슈퍼아머'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/014.png';
skillImage['스냅 샷'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/1/180.png';
skillImage['스냅 샷 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/208.png';
skillImage['스매셔'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/020.png';
skillImage['스매셔 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/372.png';
skillImage['스매쉬'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/068.png';
skillImage['스웨이'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/068.png';
skillImage['스위트 캔디바'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/248.png';
skillImage['스위트 캔디바 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/304.png';
skillImage['스위프트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/224.png';
skillImage['스윌링 스피어'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/004.png';
skillImage['스카폴드'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/168.png';
skillImage['스카폴드 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/260.png';
skillImage['스커드 더 라스'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/044.png';
skillImage['스커드 더 라스 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/068.png';
skillImage['스커드 제노사이드'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/164.png';
skillImage['스케일 그라인더'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/174.png';
skillImage['스케일 그라인더 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/194.png';
skillImage['스퀘어리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/196.png';
skillImage['스퀘어리 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/198.png';
skillImage['스타일리쉬'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/414.png';
skillImage['스타폴'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/242.png';
skillImage['스타폴 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/383.png';
skillImage['스탠바이-레디'] = 'https://bbscdn.df.nexon.com/data6/commu/202101/105006_5ff3c5ce78ed2.png';
skillImage['스탬피드'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/148.png';
skillImage['스테이블 : 디 얼티밋'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/416.png';
skillImage['스톰 스트라이크'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/372.png';
skillImage['스톰 스트라이크 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/374.png';
skillImage['스톰 엘리미네이트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/402.png';
skillImage['스톰 퀘이커'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/220.png';
skillImage['스톰 퀘이커 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/424.png';
skillImage['스트라이커의 경갑 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/126.png';
skillImage['스트레이트 샷'] = '//bbscdn.df.nexon.com/data6/commu/202106/174927_60dc3017533b8.png';
skillImage['스트리트파이터의 중갑 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/1/124.png';
skillImage['스파이럴 러쉬'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/254.png';
skillImage['스파이럴 러쉬 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/268.png';
skillImage['스파이럴 웨이브'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/328.png';
skillImage['스파이럴 프레스'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/400.png';
skillImage['스파이어'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/038.png';
skillImage['스파이어 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/232.png';
skillImage['스패로우 팩토리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/190.png';
skillImage['스패로우 팩토리 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/322.png';
skillImage['스페셜리스트의 천 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/276.png';
skillImage['스펙트럴 서치 아이'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/158.png';
skillImage['스프리건'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/230.png';
skillImage['스프리건 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/302.png';
skillImage['스피닝 디포메이션'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/292.png';
skillImage['스피닝 디포메이션 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/338.png';
skillImage['스피닝 레이드'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/237.png';
skillImage['스피닝 레이드 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/274.png';
skillImage['스피릿 본드'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/044.png';
skillImage['스핀 어택'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/356.png';
skillImage['스핀 어택 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/450.png';
skillImage['스핀 킥'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/148.png';
skillImage['스핀 킥 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/308.png';
skillImage['스핀커터'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/296.png';
skillImage['스핀커터 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/374.png';
skillImage['스핏파이어의 가죽 갑옷 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/154.png';
skillImage['슬라이딩 그랩'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/1/116.png';
skillImage['슬라이서'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/002.png';
skillImage['슬라이서 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/202.png';
skillImage['슬레이어'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/243.png';
skillImage['슬로스 바디'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/038.png';
skillImage['슬로스 바디 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/062.png';
skillImage['슬로우 힐'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/002.png';
skillImage['슬로우 힐 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/248.png';
skillImage['승리의 창'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/076.png';
skillImage['승리의 창 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/110.png';
skillImage['승천'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/018.png';
skillImage['승천 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/108.png';
skillImage['승천검'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/012.png';
skillImage['승천검 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/106.png';
skillImage['승천진'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/216.png';
skillImage['승천진 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/276.png';
skillImage['시륜검'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/002.png';
skillImage['시륜검 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/100.png';
skillImage['시폭염화부'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/188.png';
skillImage['시폭염화부 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/322.png';
skillImage['식락'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/613.png';
skillImage['식신멸진:합'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/534.png';
skillImage['식신의 군'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/220.png';
skillImage['식신의 군 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/376.png';
skillImage['신검합일'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/014.png';
skillImage['신기의 손놀림'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/494.png';
skillImage['신념의 오라'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/156.png';
skillImage['신록의 검'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/028.png';
skillImage['신록의 검 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/109.png';
skillImage['신뢰의 돌파'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/036.png';
skillImage['신뢰의 돌파 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/413.png';
skillImage['신룡의 여의주'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/232.png';
skillImage['신룡의 여의주 48히트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/232.png';
skillImage['신벌'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/304.png';
skillImage['신선의 경지'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/451.png';
skillImage['신성한 빛'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/074.png';
skillImage['신성한 빛 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/246.png';
skillImage['신속 기동'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/226.png';
skillImage['신속 기동 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/228.png';
skillImage['신속한 무기 교체'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/030.png';
skillImage['신실한 열정'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/262.png';
skillImage['신의 대행자'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/544.png';
skillImage['신의 분노'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/429.png';
skillImage['신의 은총'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/036.png';
skillImage['신의 챠크라'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/453.png';
skillImage['신체강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/078.png';
skillImage['신탁 : 신룡뇌우제'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/234.png';
skillImage['신탁의 기원'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/142.png';
skillImage['실드 브레이크'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/314.png';
skillImage['실드 브레이크 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/316.png';
skillImage['실드 스타크'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/006.png';
skillImage['실드 스타크 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/107.png';
skillImage['실버스트림'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/078.png';
skillImage['심안'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/202.png';
skillImage['심연에 드리운 어둠'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/276.png';
skillImage['심장 찌르기'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/287.png';
skillImage['심장 찌르기 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/318.png';
skillImage['심판관의 전투술'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/158.png';
skillImage['심판의 일격'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/166.png';
skillImage['심판의 일격 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/206.png';
skillImage['심혼흡성부'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/218.png';
skillImage['심혼흡성부 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/220.png';
skillImage['십자가 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/004.png';
skillImage['십자검'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/244.png';
skillImage['십자검 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/385.png';
skillImage['싸이클론 어택'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/216.png';
skillImage['싸이클론 어택 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/380.png';
skillImage['싹쓸바람'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/368.png';
skillImage['싹쓸바람 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/370.png';
skillImage['쌍검 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/016.png';
skillImage['쌍현참'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/016.png';
skillImage['쌍현참 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/074.png';
skillImage['썬더 레이지'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/828.png';
skillImage['썬더 스트라이크'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/620.png';
skillImage['썬더 콜링'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/178.png';
skillImage['썬더 콜링 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/404.png';
skillImage['썬더 해머 : 유피테르'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/419.png';
skillImage['썬버스트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/108.png';
skillImage['썬버스트 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/402.png';
skillImage['쏘우 블레이드'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/472.png';
skillImage['쓰러스트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/042.png';
skillImage['쓰러스트 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/296.png';
skillImage['아데스 팬텀'] = 'https://bbscdn.df.nexon.com/data6/commu/202010/150953_5f97b9b19f40e.png';
skillImage['아르모니아'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/814.png';
skillImage['아르스마그나'] = 'https://bbscdn.df.nexon.com/data6/commu/202008/154108_5f3b78046b2b4.png';
skillImage['아마겟돈 스트라이크'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/194.png';
skillImage['아마테라스'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/357.png';
skillImage['아수라의 판금 갑옷 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/184.png';
skillImage['아스트라 소환'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/241.png';
skillImage['아웃 스탠딩'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/02/icon/620.png';
skillImage['아웃레이지 브레이크'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/172.png';
skillImage['아웃레이지 브레이크 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/298.png';
skillImage['아이스 로드'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/032.png';
skillImage['아이스 빅 해머'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/038.png';
skillImage['아이스 빅 해머 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/156.png';
skillImage['아이스 스톤'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/10/icon/448.png';
skillImage['아이스 스톤 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/10/icon/488.png';
skillImage['아이스 실드'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/10/icon/454.png';
skillImage['아이스 실드 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/10/icon/630.png';
skillImage['아이스 오브'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/166.png';
skillImage['아이스 오브 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/418.png';
skillImage['아이스 크래쉬'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/040.png';
skillImage['아이스 크래쉬 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/154.png';
skillImage['아이스 크리스탈 샤워'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/024.png';
skillImage['아이스 크리스탈 샤워 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/134.png';
skillImage['아이스 플레이트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/10/icon/450.png';
skillImage['아이스 플레이트 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/10/icon/490.png';
skillImage['아이스 필드'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/168.png';
skillImage['아이스 필드 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/420.png';
skillImage['아이스맨'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/062.png';
skillImage['아이스맨 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/158.png';
skillImage['아이스크래프트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/180.png';
skillImage['아크리액터'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/550.png';
skillImage['아크틱 피스트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/182.png';
skillImage['아크틱 피스트 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/364.png';
skillImage['아텐 현신'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/211.png';
skillImage['아텐의 화신'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/205.png';
skillImage['아토믹 쵸퍼'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/474.png';
skillImage['아토믹 캐넌'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/270.png';
skillImage['아포칼립스'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/158.png';
skillImage['악귀무쌍'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/670.png';
skillImage['악귀연무'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/640.png';
skillImage['악귀참수'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/604.png';
skillImage['악귀참수 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/626.png';
skillImage['악귀현신'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/636.png';
skillImage['악마화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/302.png';
skillImage['악마화 평타'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/302.png';
skillImage['악몽'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/308.png';
skillImage['악신난무'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/144.png';
skillImage['악의 제전'] = '//bbscdn.df.nexon.com/data6/commu/202102/135821_6018dbed5d55d.png';
skillImage['악즉참'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/040.png';
skillImage['악즉참 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/094.png';
skillImage['안식의 날개'] = 'https://bbscdn.df.nexon.com/data6/commu/202009/175851_5f60824b871ad.png';
skillImage['알파 서포트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/464.png';
skillImage['알파 스트라이크'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/242.png';
skillImage['알파 스트라이크 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/244.png';
skillImage['암기 : 비연수리검'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/277.png';
skillImage['암기 : 비연수리검 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/434.png';
skillImage['암살'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/307.png';
skillImage['암살 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/320.png';
skillImage['암살 목표'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/024.png';
skillImage['암살기술'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/295.png';
skillImage['암살자의 마음가짐'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/293.png';
skillImage['암연검 : 기가 블레이드'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/074.png';
skillImage['암연격 : 기가슬래쉬'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/337.png';
skillImage['암영술'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/238.png';
skillImage['암월비보'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/026.png';
skillImage['암월비보 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/084.png';
skillImage['암전'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/046.png';
skillImage['암전 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/130.png';
skillImage['암흑나선창'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/210.png';
skillImage['암흑나선창 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/212.png';
skillImage['암흑의 의식'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/094.png';
skillImage['암흑참'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/030.png';
skillImage['암흑참 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/086.png';
skillImage['압도'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/044.png';
skillImage['압도 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/093.png';
skillImage['애스트럴 스톰'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/204.png';
skillImage['애시드 클라우드'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/250.png';
skillImage['애시드 클라우드 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/410.png';
skillImage['애정전시'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/834.png';
skillImage['앰플리파이'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/168.png';
skillImage['앵글 샷'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/144.png';
skillImage['앵글 샷 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/146.png';
skillImage['앵클 컷'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/066.png';
skillImage['앵클 컷 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/080.png';
skillImage['야마타오로치'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/355.png';
skillImage['야차라 불리는 자'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/628.png';
skillImage['야타가라스'] = 'https://bbscdn.df.nexon.com/data6/commu/202010/151347_5f97ba9b62f9d.png';
skillImage['야타의 거울'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/351.png';
skillImage['야행혼'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/054.png';
skillImage['야행혼 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/152.png';
skillImage['약식소환 : 럭키왁키'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/130.png';
skillImage['약식소환 : 럭키왁키 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/192.png';
skillImage['약식소환 : 레이지 햇'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/126.png';
skillImage['약식소환 : 레이지 햇 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/190.png';
skillImage['약식소환 : 뱀파이어 칠드런'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/128.png';
skillImage['약식소환 : 뱀파이어 칠드런 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/191.png';
skillImage['약식소환 : 보라스'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/120.png';
skillImage['약식소환 : 보라스 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/187.png';
skillImage['약식소환 : 빅대디'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/112.png';
skillImage['약식소환 : 빅대디 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/183.png';
skillImage['약식소환 : 젤라틴 마스크'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/118.png';
skillImage['약식소환 : 젤라틴 마스크 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/186.png';
skillImage['약식소환 : 크레이지 스핀'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/116.png';
skillImage['약식소환 : 크레이지 스핀 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/185.png';
skillImage['약식소환 : 트윙클 아티스트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/124.png';
skillImage['약식소환 : 트윙클 아티스트 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/189.png';
skillImage['약식소환 : 하트퀸'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/122.png';
skillImage['약식소환 : 하트퀸 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/188.png';
skillImage['약식소환 : 히스테릭 워리어'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/114.png';
skillImage['약식소환 : 히스테릭 워리어 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/184.png';
skillImage['양의공'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/196.png';
skillImage['양의공 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/274.png';
skillImage['양자 폭탄'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/098.png';
skillImage['양자 폭탄 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/364.png';
skillImage['어그레시브 저지먼트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/540.png';
skillImage['어둠 가르기'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/222.png';
skillImage['어둠 가르기 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/378.png';
skillImage['어둠에 피는 장미'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/765.png';
skillImage['어둠의 고리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/420.png';
skillImage['어둠의 광검 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/096.png';
skillImage['어둠의 권능'] = 'https://dundam.xyz/img/skill/320.png';
skillImage['리퍼'] = 'https://dundam.xyz/img/skill/310.png';
skillImage['에이븐 헌팅'] = 'https://dundam.xyz/img/skill/l344.png';
skillImage['어둠의 권능 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/380.png';
skillImage['어둠의 대검 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/036.png';
skillImage['어둠의 도 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/034.png';
skillImage['어둠의 둔기 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/040.png';
skillImage['어둠의 소검 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/038.png';
skillImage['어둠의 손톱'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/056.png';
skillImage['어둠의 손톱 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/204.png';
skillImage['어둠의 지배자'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/262.png';
skillImage['어둠의 친화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/060.png';
skillImage['어둠의 투창 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/238.png';
skillImage['어밴던'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/354.png';
skillImage['어벤저의 중갑 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/324.png';
skillImage['어비셜 인베이더'] = '//bbscdn.df.nexon.com/data6/commu/202103/133407_6050353f5b580.png';
skillImage['어비스'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/172.png';
skillImage['어스웜 헌트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/182.png';
skillImage['어스웜 헌트 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/302.png';
skillImage['어슬랜트 스파이럴'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/146.png';
skillImage['어슬랜트 스파이럴 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/424.png';
skillImage['어시밀레이트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/830.png';
skillImage['어트랙트 에뮤니션'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/212.png';
skillImage['어트랙트 에뮤니션 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/214.png';
skillImage['어퍼'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/384.png';
skillImage['어퍼 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/472.png';
skillImage['어퍼 슬래쉬'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/094.png';
skillImage['어퍼 슬래쉬 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/300.png';
skillImage['어퍼 실드'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/000.png';
skillImage['어퍼 실드 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/088.png';
skillImage['언홀리 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/339.png';
skillImage['언홀리 퓨리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/467.png';
skillImage['얼리전트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/02/icon/638.png';
skillImage['업화의 소용돌이'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/226.png';
skillImage['업화의 소용돌이 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/314.png';
skillImage['에너지 바운스'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/280.png';
skillImage['에너지 바운스 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/310.png';
skillImage['에디피케이션'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/102.png';
skillImage['에쉔 포크'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/012.png';
skillImage['에쉔 포크 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/274.png';
skillImage['에어레이드'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/192.png';
skillImage['에어레이드 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/380.png';
skillImage['에어리얼 점프'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/012.png';
skillImage['에어슈타이너'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/1/138.png';
skillImage['에어슈타이너 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/210.png';
skillImage['에우디아의 성채'] = '//bbscdn.df.nexon.com/data6/commu/202102/135549_6018db55a8489.png';
skillImage['에이션트 디어'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/046.png';
skillImage['에이션트 디어 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/099.png';
skillImage['에이션트 보어'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/048.png';
skillImage['에이션트 보어 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/100.png';
skillImage['에인션트 트리거'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/252.png';
skillImage['엑셀 스트라이크'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/076.png';
skillImage['엑셀 스트라이크 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/206.png';
skillImage['엑셀레이션 트리거'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/02/icon/622.png';
skillImage['엑소셋'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/200.png';
skillImage['엑소셋 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/202.png';
skillImage['엑스티시온'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/810.png';
skillImage['엑시던스'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/415.png';
skillImage['엑조틱 램펀트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/329.png';
skillImage['엔비어스 키스'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/242.png';
skillImage['엔비어스 키스 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/310.png';
skillImage['엘레멘탈 레인'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/068.png';
skillImage['엘레멘탈 레인 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/140.png';
skillImage['엘레멘탈 바머의 천 갑옷 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/092.png';
skillImage['엘레멘탈 버스터'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/098.png';
skillImage['엘레멘탈 번'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/022.png';
skillImage['엘레멘탈 번 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/352.png';
skillImage['엘레멘탈 서클'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/140.png';
skillImage['엘레멘탈 스트라이크'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/164.png';
skillImage['엘레멘탈 스트라이크 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/416.png';
skillImage['엘레멘탈 실드'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/014.png';
skillImage['엘레멘탈 캐넌'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/048.png';
skillImage['엘레멘탈 커튼'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/597.png';
skillImage['엘레멘탈 커튼 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/664.png';
skillImage['엘레멘탈 퀘이크'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/599.png';
skillImage['엘레멘탈 퀘이크 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/666.png';
skillImage['엘레멘탈 포스'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/174.png';
skillImage['엘레멘탈 포텐셜'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/536.png';
skillImage['엘레멘탈 필드'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/192.png';
skillImage['엘레멘탈마스터의 천 갑옷 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/272.png';
skillImage['엘븐 나이트의 판금 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/080.png';
skillImage['엘비쉬 점프'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/040.png';
skillImage['엘비쉬 점프 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/098.png';
skillImage['여명의 비상'] = '//bbscdn.df.nexon.com/data6/commu/202104/140904_6075277056c5e.png';
skillImage['역린격'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/516.png';
skillImage['역린격 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/518.png';
skillImage['역병의 라사'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/170.png';
skillImage['역병의 라사 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/276.png';
skillImage['역전의 승부사'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/040.png';
skillImage['역전의 칠조격'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/455.png';
skillImage['역천의 프놈'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/156.png';
skillImage['역천의 프놈 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/379.png';
skillImage['역혈기공'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/286.png';
skillImage['연속 사격'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/012.png';
skillImage['연속 사격 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/070.png';
skillImage['연속발사'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/070.png';
skillImage['연아'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/588.png';
skillImage['연아 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/614.png';
skillImage['연화검무'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/347.png';
skillImage['연화섬'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/349.png';
skillImage['연환격'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/022.png';
skillImage['연환격 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/112.png';
skillImage['봉마연화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/118.png';
skillImage['열파참'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/128.png';
skillImage['열파참 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/280.png';
skillImage['열화천도'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/252.png';
skillImage['열화천도 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/274.png';
skillImage['염인환류파 - 천지개벽'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/402.png';
skillImage['염주 사출'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/136.png';
skillImage['염폭모대주'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/298.png';
skillImage['염화참'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/042.png';
skillImage['염화참 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/208.png';
skillImage['엽해소옥'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/405.png';
skillImage['영겁 : 나스트론드'] = 'https://bbscdn.df.nexon.com/data6/commu/202011/102744_5fbdb3108c258.png';
skillImage['영광의 축복'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/112.png';
skillImage['영원한 소유'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/761.png';
skillImage['영혼의 희생'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/044.png';
skillImage['오기조원'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/194.png';
skillImage['오라 실드'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/146.png';
skillImage['오라 실드 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/326.png';
skillImage['오라 휘광'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/278.png';
skillImage['오라 휘광 11힛'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/278.png';
skillImage['오러 랜스'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/000.png';
skillImage['오러 포스 재블린'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/018.png';
skillImage['오망성진'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/449.png';
skillImage['오버 차지'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/540.png';
skillImage['오버 히트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/254.png';
skillImage['오버드라이브'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/070.png';
skillImage['오버부스트 팩'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/616.png';
skillImage['오버센스'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/240.png';
skillImage['오버페이스'] = '//bbscdn.df.nexon.com/data6/commu/202106/174229_60dc2e7578512.png';
skillImage['오버프리즈 익스텐션'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/182.png';
skillImage['오버플로우'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/540.png';
skillImage['오비탈 디재스터'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/02/icon/630.png';
skillImage['오의 : 질풍노도'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/248.png';
skillImage['오토 가드'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/028.png';
skillImage['오토 가드 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/236.png';
skillImage['오토 체이서'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/152.png';
skillImage['오퍼레이션 레이즈'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/480.png';
skillImage['오퍼링'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/334.png';
skillImage['오퍼링 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/336.png';
skillImage['오프레션'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/060.png';
skillImage['오프레션 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/106.png';
skillImage['오픈 파이어'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/566.png';
skillImage['오행(五行):벽천지격'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/536.png';
skillImage['올려차기'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/010.png';
skillImage['올려차기 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/212.png';
skillImage['옵스트럭션'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/352.png';
skillImage['옷깃 잡기'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/144.png';
skillImage['와이프 아웃'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/564.png';
skillImage['와일드 캐넌 스파이크'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/042.png';
skillImage['와일드 캐넌 스파이크 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/214.png';
skillImage['와일드 플라워'] = '//bbscdn.df.nexon.com/data6/commu/202106/173513_60dc2cc16b041.png';
skillImage['왜곡'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/10/icon/552.png';
skillImage['요원의 가죽 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/000.png';
skillImage['요원의 전투술'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/072.png';
skillImage['요인 암살'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/116.png';
skillImage['용기의 방패'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/197.png';
skillImage['용기의 은총'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/212.png';
skillImage['용독술'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/336.png';
skillImage['용맹의 아리아'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/092.png';
skillImage['용맹의 아리아 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/120.png';
skillImage['용맹의 축복'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/084.png';
skillImage['용성락'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/154.png';
skillImage['용성락 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/377.png';
skillImage['용신의 힘'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/294.png';
skillImage['용아'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/010.png';
skillImage['용아 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/414.png';
skillImage['용암지대 생성물약'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/252.png';
skillImage['용암지대 생성물약 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/416.png';
skillImage['용왕파천'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/334.png';
skillImage['용왕파천 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/423.png';
skillImage['용왕파천 지상'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/334.png';
skillImage['용의 격노'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/184.png';
skillImage['용의 격노 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/252.png';
skillImage['용인의 증거'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/245.png';
skillImage['용제출진'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/344.png';
skillImage['용제출진 단독'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/344.png';
skillImage['우드 트랩'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/10/icon/458.png';
skillImage['우로보로스'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/520.png';
skillImage['우리엘의 결계'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/290.png';
skillImage['우시르의 저주'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/226.png';
skillImage['운명왜곡'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/248.png';
skillImage['운명의 수레바퀴'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/002.png';
skillImage['운명의 수레바퀴 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/089.png';
skillImage['운명의 창'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/480.png';
skillImage['운명의 창 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/502.png';
skillImage['운석 낙하'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/10/icon/444.png';
skillImage['운석 낙하 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/10/icon/484.png';
skillImage['울부짖는 뇌광'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/332.png';
skillImage['워크라이'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/034.png';
skillImage['원귀 : 격살'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/632.png';
skillImage['원귀 : 격살 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/644.png';
skillImage['원귀 : 신풍'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/638.png';
skillImage['원귀 : 연참'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/586.png';
skillImage['원귀 : 연참 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/612.png';
skillImage['원귀 : 일섬'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/580.png';
skillImage['원귀 : 일섬 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/610.png';
skillImage['원귀 : 회천'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/602.png';
skillImage['원귀 : 회천 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/624.png';
skillImage['원무곤'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/186.png';
skillImage['원무곤 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/338.png';
skillImage['원소 융합'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/012.png';
skillImage['원소 폭격'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/170.png';
skillImage['원소의 이해'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/436.png';
skillImage['원인치 펀치'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/036.png';
skillImage['원인치 펀치 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/238.png';
skillImage['원호 사격'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/170.png';
skillImage['원호 사격 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/172.png';
skillImage['월광대지'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/264.png';
skillImage['월광비무'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/351.png';
skillImage['월광진혼곡'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/120.png';
skillImage['월식'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/122.png';
skillImage['월하무즉정야'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/407.png';
skillImage['웜홀'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/10/icon/652.png';
skillImage['웜홀 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/10/icon/654.png';
skillImage['웨스턴 파이어'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/048.png';
skillImage['웨이브'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/364.png';
skillImage['웨이브 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/454.png';
skillImage['웨이브 스핀'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/370.png';
skillImage['웨이브 스핀 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/460.png';
skillImage['웨펀마스터의 경갑 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/206.png';
skillImage['위기의 랜드러너'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/108.png';
skillImage['위대한 의지'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/054.png';
skillImage['위상변화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/002.png';
skillImage['위상변화 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/306.png';
skillImage['위핑 웨이브'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/260.png';
skillImage['위핑 웨이브 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/264.png';
skillImage['윈드 블래스터'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/210.png';
skillImage['윈드 블래스터 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/230.png';
skillImage['윈드 스톰'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/10/icon/460.png';
skillImage['윈드 스톰 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/10/icon/494.png';
skillImage['윈드 스트라이크'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/002.png';
skillImage['윈드 스트라이크 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/104.png';
skillImage['윈드 프레스'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/10/icon/462.png';
skillImage['윈드 프레스 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/10/icon/496.png';
skillImage['윈드니스'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/208.png';
skillImage['윈드밀'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/036.png';
skillImage['윈드밀 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/356.png';
skillImage['윌 드라이버'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/084.png';
skillImage['윕 어택'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/402.png';
skillImage['윕 어택 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/484.png';
skillImage['유 다이드'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/072.png';
skillImage['유탄 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/418.png';
skillImage['은탄'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/012.png';
skillImage['은탄 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/298.png';
skillImage['의지를 담은 넨'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/398.png';
skillImage['이기어검술'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/444.png';
skillImage['이단심판관의 중갑 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/018.png';
skillImage['이단의 낙인'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/224.png';
skillImage['이동 캐스팅'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/148.png';
skillImage['이동사격'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/050.png';
skillImage['이동사격 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/382.png';
skillImage['이레이저'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/062.png';
skillImage['이레이저 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/154.png';
skillImage['이면 뒤집기'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/026.png';
skillImage['이면 뒤집기 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/238.png';
skillImage['이몰레이션'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/246.png';
skillImage['이몰레이션 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/387.png';
skillImage['이미션'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/216.png';
skillImage['이미션 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/218.png';
skillImage['이베이전'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/014.png';
skillImage['이보브'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/174.png';
skillImage['이블 스트라이커'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/459.png';
skillImage['이스트레인지먼트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/227.png';
skillImage['이스트레인지먼트 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/304.png';
skillImage['이중 투척'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/074.png';
skillImage['이클립스 하이브'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/528.png';
skillImage['이터널 버스터'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/02/icon/640.png';
skillImage['이해'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/10/icon/646.png';
skillImage['익스큐서너 슬래쉬'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/361.png';
skillImage['익스터미티 페리쉬'] = 'https://bbscdn.df.nexon.com/data6/commu/202011/102829_5fbdb33d6b452.png';
skillImage['익스트림 오버킬'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/190.png';
skillImage['익시드'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/288.png';
skillImage['인게이지먼트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/354.png';
skillImage['인듀어'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/054.png';
skillImage['인법 : 바꿔치기'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/254.png';
skillImage['인법 : 바꿔치기 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/275.png';
skillImage['인법 : 육도윤회'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/256.png';
skillImage['인법 : 잔영 남기기'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/246.png';
skillImage['인법 : 통나무분신술'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/406.png';
skillImage['인법 : 허물 벗기'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/072.png';
skillImage['인법 : 허물 벗기 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/210.png';
skillImage['인술'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/226.png';
skillImage['인시디우스 파이크'] = '//bbscdn.df.nexon.com/data6/commu/202103/133231_605034df120a1.png';
skillImage['인젝션'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/166.png';
skillImage['인챈트리스의 판금 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/694.png';
skillImage['인카네이션'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/397.png';
skillImage['인크레더블'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/136.png';
skillImage['인터록 새틀라이트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/02/icon/626.png';
skillImage['인터셉트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/016.png';
skillImage['인터셉트 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/102.png';
skillImage['인테그레이션'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/320.png';
skillImage['인테그레이션 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/419.png';
skillImage['인텐션'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/164.png';
skillImage['인파이터의 경갑 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/202.png';
skillImage['인형 바구니'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/711.png';
skillImage['인형의 숲'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/771.png';
skillImage['일곱개의 대죄'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/000.png';
skillImage['일기당천 쇄패'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/812.png';
skillImage['일렉트릭 필러'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/204.png';
skillImage['일렉트릭 필러 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/206.png';
skillImage['일루젼 슬래쉬'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/400.png';
skillImage['일루젼 슬래쉬 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/482.png';
skillImage['일발화약성'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/100.png';
skillImage['일발화약성 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/216.png';
skillImage['일자전승 : 극의 진천노호'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/250.png';
skillImage['일주연환격'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/364.png';
skillImage['일주연환격 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/388.png';
skillImage['일화도강'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/393.png';
skillImage['일화도강 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/395.png';
skillImage['임무 시작'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/038.png';
skillImage['임팩트 스매쉬'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/070.png';
skillImage['임팩트 스매쉬 12회'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/070.png';
skillImage['임팩트 스매쉬 13회'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/070.png';
skillImage['임팩트 스매쉬 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/082.png';
skillImage['임팩트 콤비네이션'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/094.png';
skillImage['자각'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/0/634.png';
skillImage['자각의 실마리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/999/icon/120.png';
skillImage['자동 장전'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/090.png';
skillImage['자상'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/034.png';
skillImage['자상 10회'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/034.png';
skillImage['자연경'] = 'https://bbscdn.df.nexon.com/data6/commu/202002/152616_5e390e8833283.png';
skillImage['자연의 분노'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/201.png';
skillImage['자연의 속박'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/050.png';
skillImage['자연의 속박 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/106.png';
skillImage['자연의 수호'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/076.png';
skillImage['자이언트 스윙'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/344.png';
skillImage['자폭'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/480.png';
skillImage['작열 파동진'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/124.png';
skillImage['작열 파동진 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/490.png';
skillImage['잔류 감각'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/176.png';
skillImage['잔영의 케이가'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/016.png';
skillImage['잔영의 케이가 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/224.png';
skillImage['잠룡'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/130.png';
skillImage['잠식'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/198.png';
skillImage['장도 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/270.png';
skillImage['장미 갑옷'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/030.png';
skillImage['장미 갑옷 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/095.png';
skillImage['장창 숙련'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/064.png';
skillImage['재생의 아리아'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/094.png';
skillImage['재앙'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/334.png';
skillImage['재앙 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/494.png';
skillImage['재현'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/10/icon/554.png';
skillImage['잭 오 할로윈'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/814.png';
skillImage['잭스파이크'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/006.png';
skillImage['잭스파이크 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/358.png';
skillImage['잭프로스트 빙수'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/626.png';
skillImage['잭 프로스트 빙수'] = 'http://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/626.png';
skillImage['저 하늘의 별'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/126.png';
skillImage['저 하늘의 별 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/382.png';
skillImage['저놈 잡아라!'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/160.png';
skillImage['저놈 잡아라! 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/308.png';
skillImage['저온살균'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/482.png';
skillImage['저지먼트 콜'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/356.png';
skillImage['전설소환 : 갈애의 라모스'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/506.png';
skillImage['전설소환 : 갈애의 라모스 식선'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/591.png';
skillImage['전설소환 : 월영'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/510.png';
skillImage['전술 지휘'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/230.png';
skillImage['전장의 여신'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/574.png';
skillImage['전장의 영웅'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/446.png';
skillImage['절명의 그림자'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/309.png';
skillImage['절명창'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/310.png';
skillImage['절명창 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/312.png';
skillImage['점핑 베어 프레스'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/721.png';
skillImage['점핑 베어 프레스 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/747.png';
skillImage['접근 금지!'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/690.png';
skillImage['접근 금지! 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/733.png';
skillImage['정령 희생'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/100.png';
skillImage['정령 희생 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/476.png';
skillImage['정령소환 : 글레어린'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/214.png';
skillImage['정령소환 : 글레어린 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/372.png';
skillImage['정령소환 : 글레어린 하이브'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/594.png';
skillImage['정령소환 : 나이아스'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/028.png';
skillImage['정령소환 : 데드 멀커'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/216.png';
skillImage['정령소환 : 데드 멀커 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/382.png';
skillImage['정령소환 : 데드 멀커 하이브'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/595.png';
skillImage['정령소환 : 스토커'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/032.png';
skillImage['정령소환 : 아도르'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/026.png';
skillImage['정령소환 : 아퀘리스'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/212.png';
skillImage['정령소환 : 아퀘리스 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/408.png';
skillImage['정령소환 : 아퀘리스 하이브'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/592.png';
skillImage['정령소환 : 위스프'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/030.png';
skillImage['정령소환 : 융합소환 헤일롬'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/580.png';
skillImage['정령소환 : 정령왕 에체베리아'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/164.png';
skillImage['정령소환 : 정령왕 에체베리아 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/360.png';
skillImage['정령소환 : 정령왕 에체베리아 하이브'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/619.png';
skillImage['정령소환 : 플레임 헐크'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/210.png';
skillImage['정령소환 : 플레임 헐크 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/434.png';
skillImage['정령소환 : 플레임 헐크 하이브'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/593.png';
skillImage['정수 뿌리기'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/222.png';
skillImage['정신이 번쩍!'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/120.png';
skillImage['정의 집행 : 레미디오스의 성좌'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/542.png';
skillImage['정의의 심판'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/154.png';
skillImage['정의의 심판 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/384.png';
skillImage['정크 스핀'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/162.png';
skillImage['정크 스핀 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/260.png';
skillImage['정화의 꽃'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/330.png';
skillImage['정화의 번개'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/104.png';
skillImage['정화의 번개 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/130.png';
skillImage['정화의 불꽃'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/228.png';
skillImage['정화의 불꽃 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/316.png';
skillImage['제 7의 귀신 : 광폭의 블라슈'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/188.png';
skillImage['제6원소'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/532.png';
skillImage['제국검술'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/312.png';
skillImage['제국식 투창술'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/200.png';
skillImage['제노사이드 크러쉬'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/158.png';
skillImage['제노사이드 크러쉬 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/182.png';
skillImage['제압 사격'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/520.png';
skillImage['제압 사격 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/580.png';
skillImage['제압부'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/120.png';
skillImage['제압부 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/252.png';
skillImage['제황나선격'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/260.png';
skillImage['조율'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/10/icon/558.png';
skillImage['조인트 스매쉬'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/394.png';
skillImage['조인트 스매쉬 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/396.png';
skillImage['조준 사격'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/020.png';
skillImage['조준 사격 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/078.png';
skillImage['좀비 강령'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/044.png';
skillImage['좀비 강령 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/212.png';
skillImage['종막극'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/838.png';
skillImage['종말'] = '//bbscdn.df.nexon.com/data6/commu/202105/115145_6099f141d6fdf.png';
skillImage['종언을 고하는 빛'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/346.png';
skillImage['죄를 고하는자'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/238.png';
skillImage['죄를 깨우는 손길'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/326.png';
skillImage['죄를 깨우는 손길 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/328.png';
skillImage['죄업에 짓눌린 육신'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/026.png';
skillImage['주작부'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/260.png';
skillImage['주작부 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/386.png';
skillImage['죽음의 연무'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/345.png';
skillImage['죽음의 표식'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/346.png';
skillImage['중갑 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/0/014.png';
skillImage['중검 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/006.png';
skillImage['중화기 개조'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/448.png';
skillImage['중화기 다루기'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/114.png';
skillImage['중화기 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/340.png';
skillImage['쥬데카 디 이터널'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/196.png';
skillImage['즉결심판'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/174.png';
skillImage['즉결심판 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/214.png';
skillImage['즉결심판 소각'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/174.png';
skillImage['증명의 열쇠'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/492.png';
skillImage['증폭'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/10/icon/632.png';
skillImage['지고 뜨는 달'] = '//bbscdn.df.nexon.com/data6/commu/202104/140954_607527a28dff5.png';
skillImage['지고의 정령왕'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/820.png';
skillImage['지그 강림'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/434.png';
skillImage['지뢰진'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/298.png';
skillImage['지뢰진 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/340.png';
skillImage['지배력 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/607.png';
skillImage['지배의 고리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/576.png';
skillImage['지의 식신 - 현무'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/128.png';
skillImage['지하드'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/329.png';
skillImage['진 뇌연격'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/282.png';
skillImage['진 뇌연격 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/672.png';
skillImage['진(眞) : 각성연환격'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/414.png';
skillImage['진격(陣擊)의 황룡'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/461.png';
skillImage['진공참'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/108.png';
skillImage['진공참 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/302.png';
skillImage['질풍가도'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/218.png';
skillImage['질풍가도 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/422.png';
skillImage['질풍각'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/1/128.png';
skillImage['질풍각 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/170.png';
skillImage['질풍경초'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/410.png';
skillImage['질풍타'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/188.png';
skillImage['질풍타 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/282.png';
skillImage['짊어진 자의 낫 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/008.png';
skillImage['집중 사격'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/238.png';
skillImage['집중 사격 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/240.png';
skillImage['집중의 오라 실드'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/194.png';
skillImage['징벌'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/550.png';
skillImage['징벌의 일격'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/164.png';
skillImage['징벌의 일격 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/204.png';
skillImage['차륜형'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/306.png';
skillImage['차원 : 디멘션 리프'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/260.png';
skillImage['차원 : 디멘션 리프 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/292.png';
skillImage['차원 : 시공폭뢰'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/264.png';
skillImage['차원 : 시공폭뢰 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/296.png';
skillImage['차원 : 실루엣'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/256.png';
skillImage['차원 : 실루엣 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/288.png';
skillImage['차원 : 싱귤래리티 매니플레이션'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/404.png';
skillImage['차원 : 액시던트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/252.png';
skillImage['차원 : 액시던트 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/284.png';
skillImage['차원 : 차원광'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/258.png';
skillImage['차원 : 차원광 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/290.png';
skillImage['차원 : 차원회랑'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/280.png';
skillImage['차원 : 차원회랑 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/302.png';
skillImage['차원 : 칼레이도스코프'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/262.png';
skillImage['차원 : 칼레이도스코프 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/294.png';
skillImage['차원 : 패러다임 디토네이션'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/272.png';
skillImage['차원 : 패러다임 디토네이션 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/426.png';
skillImage['차원 : 포지트론 블래스트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/276.png';
skillImage['차원 : 포지트론 블래스트 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/306.png';
skillImage['차원괴리 : 금단의 연회'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/398.png';
skillImage['차원괴리 : 금단의 연회 니알리x'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/398.png';
skillImage['차원괴리 : 이성붕괴'] = 'https://bbscdn.df.nexon.com/data6/commu/202011/105221_5fbdb8d5e5022.png';
skillImage['차원일치'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/512.png';
skillImage['차지 버스트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/216.png';
skillImage['차지 버스트 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/524.png';
skillImage['차지 익스플로전'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/502.png';
skillImage['차지 익스플로전 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/558.png';
skillImage['차지 크래시'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/150.png';
skillImage['차지 크래시 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/324.png';
skillImage['차크라 웨펀 숙련'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/410.png';
skillImage['차크라 웨펀 숙련 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/412.png';
skillImage['차크라 웨펀 착용 가능'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/228.png';
skillImage['착취'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/166.png';
skillImage['찰나의 깨달음'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/999/icon/116.png';
skillImage['참격혼'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/068.png';
skillImage['참마검'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/160.png';
skillImage['참마검 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/381.png';
skillImage['참살(慘殺)'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/642.png';
skillImage['참성연격 : 인피니티 노바'] = '//bbscdn.df.nexon.com/data6/commu/202102/140529_6018dd99cf8ec.png';
skillImage['참수'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/162.png';
skillImage['참수 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/202.png';
skillImage['참회의 망치'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/152.png';
skillImage['참회의 망치 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/268.png';
skillImage['창 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/130.png';
skillImage['창룡격'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/198.png';
skillImage['창세의 빛'] = '//bbscdn.df.nexon.com/data6/commu/202105/115104_6099f1181bfdd.png';
skillImage['창조'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/10/icon/560.png';
skillImage['창조의 공간'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/10/icon/550.png';
skillImage['생명의 나무'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/10/icon/550.png';
skillImage['채찍질'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/050.png';
skillImage['채찍질 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/310.png';
skillImage['처단'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/028.png';
skillImage['처단 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/118.png';
skillImage['처형'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/306.png';
skillImage['척결의 역십자'] = 'https://bbscdn.df.nexon.com/data6/commu/202009/175651_5f6081d3e8079.png';
skillImage['천 갑옷마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/0/036.png';
skillImage['천격'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/014.png';
skillImage['천격 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/418.png';
skillImage['천공검'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/142.png';
skillImage['천공검 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/194.png';
skillImage['천녀기우제'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/332.png';
skillImage['천녀동화'] = 'https://bbscdn.df.nexon.com/data6/commu/202009/175808_5f6082206a1e9.png';
skillImage['천뇌 바주라'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/430.png';
skillImage['천뇌 바주라 잔딜'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/430.png';
skillImage['천뇌 바주라 피니쉬'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/430.png';
skillImage['천라지망'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/112.png';
skillImage['천뢰분신보'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/400.png';
skillImage['천마 섬광'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/078.png';
skillImage['천붕지괴'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/048.png';
skillImage['천상의 멜로디'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/160.png';
skillImage['천상의 하모니'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/148.png';
skillImage['천수천안'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/112.png';
skillImage['천제극섬'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/687.png';
skillImage['천지역전 : 무뢰(武雷)'] = 'https://bbscdn.df.nexon.com/data6/commu/202002/153727_5e39112718c3b.png';
skillImage['천지창조'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/10/icon/648.png';
skillImage['천하포무 : 투극'] = '//bbscdn.df.nexon.com/data6/commu/202103/133536_6050359850580.png';
skillImage['철금강'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/1/056.png';
skillImage['철금강 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/1/252.png';
skillImage['철벽방어'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/032.png';
skillImage['철벽방어 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/097.png';
skillImage['철산고'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/016.png';
skillImage['철산고 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/242.png';
skillImage['청혈투귀'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/590.png';
skillImage['체이서'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/656.png';
skillImage['체이서 120히트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/150.png';
skillImage['체이서 40히트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/150.png';
skillImage['체이서 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/336.png';
skillImage['체이서 사출'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/150.png';
skillImage['체이서 에볼루션'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/530.png';
skillImage['체이서 클러스터'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/662.png';
skillImage['체이서 프레스'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/190.png';
skillImage['체이서 프레스 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/422.png';
skillImage['체인 그랩'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/342.png';
skillImage['체인 드라이브'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/236.png';
skillImage['체인 디바이더'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/568.png';
skillImage['체인 리액션'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/349.png';
skillImage['체인 리액션 사슬단검'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/349.png';
skillImage['체인 리액션 피니쉬'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/349.png';
skillImage['체인 스내치'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/474.png';
skillImage['체인 스트라이크'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/199.png';
skillImage['체인 커넥션'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/356.png';
skillImage['체인라이트닝'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/020.png';
skillImage['체인라이트닝 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/128.png';
skillImage['체인러쉬'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/020.png';
skillImage['체인러쉬 30회'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/020.png';
skillImage['체인소'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/022.png';
skillImage['체인소 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/214.png';
skillImage['체인소 러시'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/166.png';
skillImage['체인소 러시 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/422.png';
skillImage['체인지 실드'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/086.png';
skillImage['체페슈'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/356.png';
skillImage['초열혼폭술'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/240.png';
skillImage['초월의 룬'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/508.png';
skillImage['초월의 크리스탈'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/601.png';
skillImage['초핑 해머'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/076.png';
skillImage['초핑 해머 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/390.png';
skillImage['최후의 심판'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/548.png';
skillImage['최후의 일격'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/254.png';
skillImage['최후의 일격 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/256.png';
skillImage['최후의 투지'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/160.png';
skillImage['추락하는 영혼'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/328.png';
skillImage['축기'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/330.png';
skillImage['축염포'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/188.png';
skillImage['축염포 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/188.png';
skillImage['축지성촌'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/272.png';
skillImage['충실한 종'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/228.png';
skillImage['충전 레이저 라이플'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/088.png';
skillImage['치유의 기도'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/080.png';
skillImage['치유의 기도 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/116.png';
skillImage['칠링 팬스'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/114.png';
skillImage['칠링 팬스 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/424.png';
skillImage['칠흑의 공포'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/196.png';
skillImage['카디널 익스큐션'] = 'https://bbscdn.df.nexon.com/data6/commu/202009/175838_5f60823ed24c8.png';
skillImage['카라콜'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/246.png';
skillImage['카라콜 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/248.png';
skillImage['카리스마'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/401.png';
skillImage['카모플라쥬'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/102.png';
skillImage['카오스의 중갑 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/132.png';
skillImage['카이'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/062.png';
skillImage['카잔'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/052.png';
skillImage['카타르시스'] = '//bbscdn.df.nexon.com/data6/commu/202106/174947_60dc302bac0a8.png';
skillImage['칼날 바람'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/408.png';
skillImage['칼날 바람 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/410.png';
skillImage['칼날돌풍'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/301.png';
skillImage['칼날돌풍 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/322.png';
skillImage['캐넌볼'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/194.png';
skillImage['캐넌볼 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/368.png';
skillImage['커럽션'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/350.png';
skillImage['커럽션 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/352.png';
skillImage['커맨드먼트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/350.png';
skillImage['커스 스피어'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/040.png';
skillImage['커스 스피어 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/156.png';
skillImage['커스 오브 테러베어'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/769.png';
skillImage['컨버전스 리액트'] = 'https://bbscdn.df.nexon.com/data6/commu/202011/102516_5fbdb27cbf963.png';
skillImage['컨버전스 캐넌'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/190.png';
skillImage['컨센트레이트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/162.png';
skillImage['컨센트레이트 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/414.png';
skillImage['컨실리에이션'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/229.png';
skillImage['컨실리에이션 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/306.png';
skillImage['컨제스트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/240.png';
skillImage['컨페션'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/258.png';
skillImage['컨페션 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/266.png';
skillImage['컷 인 대쉬'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/024.png';
skillImage['컷 인 대쉬 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/050.png';
skillImage['코버트 액션'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/414.png';
skillImage['코버트 액션 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/416.png';
skillImage['코스믹 캘러미티'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/832.png';
skillImage['코어 블레이드 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/272.png';
skillImage['코어 실드'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/160.png';
skillImage['코어 실드 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/162.png';
skillImage['코어 패러다임'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/294.png';
skillImage['코어 프렉시스'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/186.png';
skillImage['코쿤 오브 언데드'] = 'https://bbscdn.df.nexon.com/data6/commu/202010/151032_5f97b9d8a53d5.png';
skillImage['코크 스크류 블로우'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/508.png';
skillImage['코크 스크류 블로우 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/510.png';
skillImage['콜 시그널 : 커버링'] = '//bbscdn.df.nexon.com/data6/commu/202104/140848_607527603f893.png';
skillImage['콤보 본능'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/388.png';
skillImage['콰이터스 리뎀션'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/401.png';
skillImage['쾌속검'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/004.png';
skillImage['쾌속검 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/102.png';
skillImage['쾌속의 도 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/146.png';
skillImage['쿠나이 던지기'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/050.png';
skillImage['쿠나이 던지기 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/150.png';
skillImage['쿠노이치의 천 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/248.png';
skillImage['쿠사나기의 검'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/359.png';
skillImage['쿼드러플 드라이브'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/146.png';
skillImage['퀘이사 익스플로젼'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/572.png';
skillImage['퀵 패리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/104.png';
skillImage['퀵 펀토'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/048.png';
skillImage['퀵 펀토 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/114.png';
skillImage['큐어'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/070.png';
skillImage['큐어 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/112.png';
skillImage['크라우치'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/1/244.png';
skillImage['크라우치 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/172.png';
skillImage['크럭스 오브 빅토리아'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/260.png';
skillImage['크럭스 오브 샤피엘'] = 'https://bbscdn.df.nexon.com/data6/commu/202009/175555_5f60819b00d32.png';
skillImage['크레센트 슬래쉬'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/032.png';
skillImage['크레센트 슬래쉬 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/102.png';
skillImage['크레이지 발칸'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/034.png';
skillImage['크레이지 발칸 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/230.png';
skillImage['크로스 크래쉬'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/262.png';
skillImage['크로스 크래쉬 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/392.png';
skillImage['크루세이더의 판금 갑옷 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/012.png';
skillImage['크리스탈 블레이드'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/178.png';
skillImage['크리스탈 어택'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/018.png';
skillImage['크리스탈 어택 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/126.png';
skillImage['크리핑'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/314.png';
skillImage['크림슨 가든'] = '//bbscdn.df.nexon.com/data6/commu/202101/104740_5ff3c53ca2abf.png';
skillImage['클라이막스'] = '//bbscdn.df.nexon.com/data6/commu/202104/141206_6075282691904.png';
skillImage['클로 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/1/094.png';
skillImage['클로즈 킥'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/022.png';
skillImage['클로즈 킥 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/220.png';
skillImage['클리브'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/026.png';
skillImage['클리브 어퍼 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/026.png';
skillImage['킬 포인트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/478.png';
skillImage['타락의 칠죄종'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/240.png';
skillImage['타락의 칠죄종 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/276.png';
skillImage['타이머 밤'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/366.png';
skillImage['타이머 밤 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/426.png';
skillImage['타임 브레이크'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/510.png';
skillImage['타임 스톱'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/516.png';
skillImage['타임 포워드'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/10/icon/548.png';
skillImage['탄천신룡진'] = 'https://bbscdn.df.nexon.com/data6/commu/202009/175756_5f6082144570d.png';
skillImage['탈명대염진'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/236.png';
skillImage['탈명대염진 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/324.png';
skillImage['탐식의 크레바스'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/660.png';
skillImage['탐욕의 번제'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/072.png';
skillImage['탑스핀'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/238.png';
skillImage['탑스핀 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/360.png';
skillImage['태도 용제무쌍'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/239.png';
skillImage['태도 용제무쌍 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/276.png';
skillImage['태도 용제무쌍 올려베기'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/239.png';
skillImage['태사자후'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/214.png';
skillImage['태사자후 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/384.png';
skillImage['태산압정'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/008.png';
skillImage['태산압정 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/084.png';
skillImage['태양의 숨결'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/366.png';
skillImage['태초의 공포 : 모로스'] = 'https://bbscdn.df.nexon.com/data6/commu/202010/151045_5f97b9e5bf7fb.png';
skillImage['태풍의 전조'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/434.png';
skillImage['택티컬 오펜시브'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/290.png';
skillImage['터미네이트 슬러거'] = '//bbscdn.df.nexon.com/data6/commu/202104/141150_6075281611251.png';
skillImage['터뷸런트 럼블'] = 'https://bbscdn.df.nexon.com/data6/commu/202011/102915_5fbdb36b43beb.png';
skillImage['턴 힐'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/368.png';
skillImage['테러블 로어'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/763.png';
skillImage['테러블 로어 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/775.png';
skillImage['테스타먼트'] = 'https://bbscdn.df.nexon.com/data6/commu/202106/175449_60dc31596c3aa.png';
skillImage['테아나 변신~!!'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/220.png';
skillImage['테이저'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/192.png';
skillImage['테이저 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/194.png';
skillImage['테크니컬 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/286.png';
skillImage['테크트리 : 데몬 매지션'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/156.png';
skillImage['테크트리 : 데몬 솔져'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/154.png';
skillImage['텔레포트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/000.png';
skillImage['토르베지노'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/326.png';
skillImage['토먼트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/342.png';
skillImage['토먼트 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/344.png';
skillImage['퇴마사의 중갑 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/134.png';
skillImage['퇴마사의 천 갑옷 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/204.png';
skillImage['퇴마사의 판금 갑옷 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/200.png';
skillImage['퇴마의 기운'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/142.png';
skillImage['퇴마의 서'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/524.png';
skillImage['투귀혼연살(鬪鬼魂連殺)'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/675.png';
skillImage['투기 발산'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/196.png';
skillImage['툰드라의 가호'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/100.png';
skillImage['툼스톤'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/090.png';
skillImage['툼스톤 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/326.png';
skillImage['툼스톤 스웜프'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/542.png';
skillImage['툼스톤 스웜프 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/554.png';
skillImage['트랜센던스 라이트'] = '//bbscdn.df.nexon.com/data6/commu/202102/140133_6018dcada500e.png';
skillImage['트랜스 폼 : G-0 배틀로이드'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/256.png';
skillImage['트랜스포메이션'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/506.png';
skillImage['트랜스폼 : G-2 롤링썬더'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/176.png';
skillImage['트랜스폼 : G-2 롤링썬더 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/392.png';
skillImage['트랜스폼 : G-2 롤링썬더 전자파'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/176.png';
skillImage['트랜스폼 : G-3 랩터'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/178.png';
skillImage['트랜스폼 : G-3 랩터 1초'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/178.png';
skillImage['트랜스폼 : G-3 랩터 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/394.png';
skillImage['트러블 슈터의 중갑 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/002.png';
skillImage['트루 블러드'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/390.png';
skillImage['트리플 스탭'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/408.png';
skillImage['트리플 탭'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/522.png';
skillImage['트리플 탭 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/524.png';
skillImage['트와일라잇 유니콘'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/203.png';
skillImage['트윈 펀토'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/126.png';
skillImage['트윈 펀토 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/130.png';
skillImage['트윙클 스매쉬'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/290.png';
skillImage['트윙클 스매쉬 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/676.png';
skillImage['특성탄'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/002.png';
skillImage['특수기동전대 \'블랙 로즈\''] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/170.png';
skillImage['티 타임'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/268.png';
skillImage['파계검 : 라그나로크'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/341.png';
skillImage['파동 해제'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/100.png';
skillImage['파동 해제 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/306.png';
skillImage['파동각인'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/032.png';
skillImage['파동검 : 인다라망'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/422.png';
skillImage['파동검 빙인'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/000.png';
skillImage['천조'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/000.png';
skillImage['파동검 빙인 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/308.png';
skillImage['파동검 지열'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/006.png';
skillImage['광익'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/006.png';
skillImage['파동검 지열 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/242.png';
skillImage['파동검 폭염'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/002.png';
skillImage['섬창'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/002.png';
skillImage['파동검 폭염 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/310.png';
skillImage['파동교류 : 수폭'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/679.png';
skillImage['파동비전 : 혜안'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/677.png';
skillImage['파동신결 : 천뢰'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/681.png';
skillImage['파동연환창'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/006.png';
skillImage['파동연환창 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/088.png';
skillImage['파동의 눈'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/194.png';
skillImage['차륜'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/194.png';
skillImage['파동의 눈 평타'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/194.png';
skillImage['파르티잔 슈트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/156.png';
skillImage['파르티잔 슈트 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/184.png';
skillImage['파멸의 강타'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/465.png';
skillImage['파성추'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/146.png';
skillImage['파성추 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/179.png';
skillImage['파쇄권'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/210.png';
skillImage['파쇄권 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/386.png';
skillImage['파쇄의 둔기 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/152.png';
skillImage['파워 슬램'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/004.png';
skillImage['파이널 디스트럭션'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/305.png';
skillImage['파이널 디스트럭션 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/324.png';
skillImage['파이널 어택'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/168.png';
skillImage['파이널 어택 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/193.png';
skillImage['파이어 브레스'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/231.png';
skillImage['파이어 브레스 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/268.png';
skillImage['파이어로드'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/016.png';
skillImage['파이어로드 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/124.png';
skillImage['파이어볼트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/514.png';
skillImage['파이어월'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/10/icon/446.png';
skillImage['파이어월 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/10/icon/486.png';
skillImage['파천세'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/010.png';
skillImage['판금 갑옷 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/0/018.png';
skillImage['판타지 오브 네이쳐'] = '//bbscdn.df.nexon.com/data6/commu/202102/135650_6018db925c780.png';
skillImage['팔라딘의 판금 방어구 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/324.png';
skillImage['팔티잔 스큐어'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/180.png';
skillImage['팔티잔 스큐어 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/300.png';
skillImage['패러트루퍼'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/056.png';
skillImage['패러트루퍼 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/120.png';
skillImage['패스트 드로우'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/430.png';
skillImage['패스트 힐'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/040.png';
skillImage['패스티스트 건'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/042.png';
skillImage['패황연격'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/283.png';
skillImage['팬시 트위스트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/152.png';
skillImage['팬시 트위스트 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/181.png';
skillImage['팬텀 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/440.png';
skillImage['팬텀 베일'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/358.png';
skillImage['팬텀 소드'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/506.png';
skillImage['팬텀스트롬'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/080.png';
skillImage['팽'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/338.png';
skillImage['팽 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/340.png';
skillImage['퍼니셔'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/010.png';
skillImage['퍼니셔 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/302.png';
skillImage['퍼니쉬먼트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/333.png';
skillImage['퍼밀리어와 친하게 지내기'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/628.png';
skillImage['퍼스트 프라나 : 금단의 결정체'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/278.png';
skillImage['퍼페티어'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/698.png';
skillImage['퍼펙트 가드'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/014.png';
skillImage['퍼펙트 배팅'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/128.png';
skillImage['퍼펙트 배팅 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/302.png';
skillImage['퍼펙트 배팅 스핀'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/128.png';
skillImage['페니트레잇 헤드'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/172.png';
skillImage['페니트레잇 헤드 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/192.png';
skillImage['페이스풀'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/262.png';
skillImage['페이탈 블러드'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/654.png';
skillImage['페이탈 블리츠'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/347.png';
skillImage['페이탈 엘레멘트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/096.png';
skillImage['페이탈리티'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/386.png';
skillImage['페인킬러'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/406.png';
skillImage['편애'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/708.png';
skillImage['평타 150히트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/991.png';
skillImage['평타 1탄창'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/436.png';
skillImage['평타 40히트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/991.png';
skillImage['포기브니스'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/225.png';
skillImage['포기브니스 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/302.png';
skillImage['포스티쿠스 루나'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/822.png';
skillImage['포식의 델라리온'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/824.png';
skillImage['포인트 블랭크'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/262.png';
skillImage['포인트 블랭크 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/306.png';
skillImage['폭검'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/214.png';
skillImage['폭검 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/298.png';
skillImage['폭류나선'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/044.png';
skillImage['폭류나선 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/120.png';
skillImage['폭멸장'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/371.png';
skillImage['폭멸장 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/373.png';
skillImage['폭명기검'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/058.png';
skillImage['폭명기검 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/126.png';
skillImage['폭발탄'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/000.png';
skillImage['폭발탄 1탄창'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/000.png';
skillImage['폭발탄 2발'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/000.png';
skillImage['폭발탄 4발'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/000.png';
skillImage['폭발탄 7발'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/000.png';
skillImage['폭음폭식'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/254.png';
skillImage['폭주'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/078.png';
skillImage['폭주 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/244.png';
skillImage['폭풍의 눈'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/214.png';
skillImage['폭풍의 눈 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/234.png';
skillImage['폭풍의 숨결'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/326.png';
skillImage['폭풍의 숨결 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/328.png';
skillImage['폭풍의 숨결 일반'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/326.png';
skillImage['폴링 데스'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/278.png';
skillImage['풀바디'] = 'https://bbscdn.df.nexon.com/data6/commu/202106/175437_60dc314d79e45.png';
skillImage['풍신의 힘'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/376.png';
skillImage['퓨리파잉'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/219.png';
skillImage['퓨리파잉 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/296.png';
skillImage['퓨전 체이서'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/340.png';
skillImage['퓨전 체이서 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/428.png';
skillImage['퓨전 크래프트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/276.png';
skillImage['프라이드 악셀'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/034.png';
skillImage['프라이드 악셀 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/058.png';
skillImage['프라이스 오브 미스트러스트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/250.png';
skillImage['프레센시아'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/808.png';
skillImage['프레셔 월'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/226.png';
skillImage['프렌지'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/134.png';
skillImage['프렌지 평타'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/134.png';
skillImage['프로스트 헤드'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/092.png';
skillImage['프로스트 헤드 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/314.png';
skillImage['프로즌웨폰 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/186.png';
skillImage['프리스비'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/498.png';
skillImage['프리스비 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/602.png';
skillImage['플라즈마 부스터'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/574.png';
skillImage['플라즈마 부스터 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/610.png';
skillImage['플래시 글로브'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/022.png';
skillImage['플래시 글로브 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/394.png';
skillImage['플래시 스크랩'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/230.png';
skillImage['플레게의 정수'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/170.png';
skillImage['플레게의 정수 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/210.png';
skillImage['플레게의 화염병'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/168.png';
skillImage['플레게의 화염병 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/208.png';
skillImage['플레임 서클'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/022.png';
skillImage['플레임 서클 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/132.png';
skillImage['플레임 스트라이크'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/180.png';
skillImage['플레임 스트라이크 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/432.png';
skillImage['플레임 허리케인'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/10/icon/452.png';
skillImage['플레임 허리케인 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/10/icon/492.png';
skillImage['플로레 비비기'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/094.png';
skillImage['플로레 비비기 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/316.png';
skillImage['플로레 전기토끼'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/540.png';
skillImage['플로레 전기토끼 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/678.png';
skillImage['플로레 컬라이더'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/268.png';
skillImage['플로레 컬라이더 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/436.png';
skillImage['플루토'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/096.png';
skillImage['플루토 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/438.png';
skillImage['피날레 오브 더 퀸'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/420.png';
skillImage['피니쉬!'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/004.png';
skillImage['피니쉬! 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/090.png';
skillImage['피로 적신 가죽 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/362.png';
skillImage['피스톨 카빈'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/534.png';
skillImage['피스톨 카빈 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/606.png';
skillImage['피어스'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/146.png';
skillImage['피어스 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/298.png';
skillImage['피어스 스트라이크'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/358.png';
skillImage['피어스 스트라이크 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/452.png';
skillImage['피어스 오브 아이스'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/036.png';
skillImage['피어스 오브 아이스 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/152.png';
skillImage['피어싱 드라이브'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/242.png';
skillImage['피어싱 드라이브 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/244.png';
skillImage['피카레스크'] = 'https://bbscdn.df.nexon.com/data6/commu/202002/153339_5e39104331027.png';
skillImage['핀포인트 러쉬'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/198.png';
skillImage['필멸의 습격'] = 'https://bbscdn.df.nexon.com/data6/commu/202002/152758_5e390eeef37fa.png';
skillImage['필살검 천귀살'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/826.png';
skillImage['하급 정령 소환'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/605.png';
skillImage['하급정령 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/328.png';
skillImage['하나의 정의'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/538.png';
skillImage['하베스팅'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/715.png';
skillImage['하베스팅 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/745.png';
skillImage['하이테크놀로지'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/5/icon/612.png';
skillImage['하이퍼 플레어'] = 'https://bbscdn.df.nexon.com/data6/commu/202101/104912_5ff3c598b2b89.png';
skillImage['학살의 발라크르 강령'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/058.png';
skillImage['학살의 발라크르 강령 강격'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/363.png';
skillImage['학살의 발라크르 강령 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/174.png';
skillImage['학살의 발라크르 강령 살육난무'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/132.png';
skillImage['학살의 발라크르 강령 안개뿜기'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/058.png';
skillImage['학살의 발라크르 강령 안개뿜기 + 강격'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/058.png';
skillImage['학살자의 공포'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/124.png';
skillImage['한계 개방'] = 'https://bbscdn.df.nexon.com/data6/commu/202002/152740_5e390edc01529.png';
skillImage['한놈만 팬다!!'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/176.png';
skillImage['한놈만 팬다!! 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/300.png';
skillImage['합격 : 나락참'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/634.png';
skillImage['합격 : 나락참 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/646.png';
skillImage['합격 : 월령참'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/600.png';
skillImage['합격 : 월령참 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/622.png';
skillImage['합격 : 이혼일섬'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/594.png';
skillImage['합격 : 이혼일섬 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/616.png';
skillImage['합티즘'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/482.png';
skillImage['합티즘 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/484.png';
skillImage['항마신장'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/532.png';
skillImage['해머 킥'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/004.png';
skillImage['해머 킥 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/222.png';
skillImage['핵 펀치'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/425.png';
skillImage['핼로윈 버스터'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/104.png';
skillImage['핼로윈 버스터 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/334.png';
skillImage['행오버'] = '//bbscdn.df.nexon.com/data6/commu/202106/173432_60dc2c984e4ba.png';
skillImage['허리케인'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/028.png';
skillImage['허리케인 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/158.png';
skillImage['허리케인 롤'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/060.png';
skillImage['허리케인 롤 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/396.png';
skillImage['허리케인 롤 태풍'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/060.png';
skillImage['허리케인 슈퍼 플렉스'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/320.png';
skillImage['헌터즈 무브셋'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/160.png';
skillImage['헌터즈 샤프니스'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/274.png';
skillImage['헤드샷'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/034.png';
skillImage['헤드샷 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/304.png';
skillImage['헤드온 챠지'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/028.png';
skillImage['헤드온 챠지 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/098.png';
skillImage['헤븐리 컴비네이션'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/178.png';
skillImage['헤븐리 컴비네이션 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/398.png';
skillImage['헥스'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/759.png';
skillImage['헥스 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/773.png';
skillImage['헥토파스칼 킥'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/024.png';
skillImage['헥토파스칼 킥 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/224.png';
skillImage['헬 마치'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/444.png';
skillImage['헬름 샷'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/046.png';
skillImage['헬름 샷 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/112.png';
skillImage['헬릭스 다이브'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/264.png';
skillImage['헬릭스 다이브 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/308.png';
skillImage['헬베루즈'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/330.png';
skillImage['헬베루즈 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/332.png';
skillImage['헬터 스켈터'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/1/024.png';
skillImage['헬터 스켈터 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/1/254.png';
skillImage['현신 : 신룡진노'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/300.png';
skillImage['현월참'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/008.png';
skillImage['현월참 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/066.png';
skillImage['현자의 돌'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/524.png';
skillImage['혈관을 흐르는 맹독'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/142.png';
skillImage['혈귀극도 : 파멸'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/668.png';
skillImage['혈기왕성'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/168.png';
skillImage['혈마검'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/365.png';
skillImage['혈마검 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/391.png';
skillImage['혈문지괴검'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/411.png';
skillImage['혈십자'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/044.png';
skillImage['혈십자검'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/302.png';
skillImage['혈십자검 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/304.png';
skillImage['혈지군무'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/060.png';
skillImage['혈지군무 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/128.png';
skillImage['혈화난무'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/064.png';
skillImage['혈화난무 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/096.png';
skillImage['혜성만리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/232.png';
skillImage['혜성만리 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/300.png';
skillImage['호격권'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/328.png';
skillImage['호신연격'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/006.png';
skillImage['호신연격 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/256.png';
skillImage['호포'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/062.png';
skillImage['혼백참'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/598.png';
skillImage['혼백참 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/620.png';
skillImage['혼혈'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/164.png';
skillImage['홀드업!'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/285.png';
skillImage['홀드업! 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/319.png';
skillImage['홀리 생츄어리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/417.png';
skillImage['홀리 스매쉬'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/078.png';
skillImage['홀리 스매쉬 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/114.png';
skillImage['홀리 써클릿'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/100.png';
skillImage['홀리 써클릿 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/128.png';
skillImage['홀리 카운터'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/080.png';
skillImage['홀리 카운터 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/400.png';
skillImage['홀리 플래쉬'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/088.png';
skillImage['홀리 플래쉬 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/118.png';
skillImage['홉 스매쉬'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/372.png';
skillImage['홉 스매쉬 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/9/icon/462.png';
skillImage['홍염 질풍각'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/020.png';
skillImage['홍염 질풍각 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/152.png';
skillImage['화끈한 애정'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/713.png';
skillImage['화끈한 애정 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/743.png';
skillImage['화둔 : 홍염'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/232.png';
skillImage['화력개방'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/268.png';
skillImage['화약 다루기'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/054.png';
skillImage['화염'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/10/icon/464.png';
skillImage['화염 강타'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/078.png';
skillImage['화염 강타 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/370.png';
skillImage['화염 방패'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/056.png';
skillImage['화염 방패 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/116.png';
skillImage['화염구'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/230.png';
skillImage['화염구 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/265.png';
skillImage['화염선풍'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/236.png';
skillImage['화염선풍 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/268.png';
skillImage['화염의 각'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/030.png';
skillImage['이중개방'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/030.png';
skillImage['화염의 인'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/397.png';
skillImage['화염쿠나이 30히트'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/359.png';
skillImage['화형'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/14/icon/230.png';
skillImage['환검'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/006.png';
skillImage['환검 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/104.png';
skillImage['환수 강화 오라'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/042.png';
skillImage['환수 폭주'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/040.png';
skillImage['환영 다중 수리검'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/234.png';
skillImage['환영 다중 수리검 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/267.png';
skillImage['환영검무'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/156.png';
skillImage['환영검무 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/328.png';
skillImage['환영폭쇄'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/154.png';
skillImage['환영폭쇄 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/310.png';
skillImage['환청'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/326.png';
skillImage['황룡난무'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/568.png';
skillImage['황룡천공'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/198.png';
skillImage['황룡천공 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/440.png';
skillImage['황야의 무법자'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/138.png';
skillImage['황야의 폭풍'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/02/icon/642.png';
skillImage['황혼의 날개'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/292.png';
skillImage['회륜'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/186.png';
skillImage['회심의 랜드러너'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/2/icon/108.png';
skillImage['회오리 바람'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/206.png';
skillImage['회오리 바람 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/226.png';
skillImage['회전격'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/024.png';
skillImage['회전격 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/114.png';
skillImage['회전투창'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/028.png';
skillImage['회전투창 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/142.png';
skillImage['효율적인 연소'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/116.png';
skillImage['훅 앤 쓰로우'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/052.png';
skillImage['훅 앤 쓰로우 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/118.png';
skillImage['휘광의 성체'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/262.png';
skillImage['휘몰아치는 바람'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/212.png';
skillImage['휘몰아치는 바람 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/232.png';
skillImage['휘몰아치는 질풍의 봉 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/8/icon/384.png';
skillImage['휩쓸기'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/250.png';
skillImage['휩쓸기 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/252.png';
skillImage['흉멸인법진'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/262.png';
skillImage['흑광폭살'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/258.png';
skillImage['흑광폭살 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/272.png';
skillImage['흑마법 빗자루'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/3/icon/696.png';
skillImage['흑마법서 : 망자의 혼'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/337.png';
skillImage['흑사진'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/068.png';
skillImage['흑사진 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/160.png';
skillImage['흑염검'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/532.png';
skillImage['흑염의 칼라'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/178.png';
skillImage['흑염의 칼라 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/330.png';
skillImage['흑염의 칼라 평타'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/178.png';
skillImage['흑염의 칼라 폭발'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/0/icon/178.png';
skillImage['흑진광풍'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/7/icon/246.png';
skillImage['흑패성진 : 사문격쇄'] = 'https://bbscdn.df.nexon.com/data6/commu/202002/153755_5e3911437bc47.png';
skillImage['흡기'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/180.png';
skillImage['흡기 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/278.png';
skillImage['흡기공탄'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/318.png';
skillImage['흡기암경파'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/082.png';
skillImage['흡기암경파 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/216.png';
skillImage['흩날리는 천염화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/156.png';
skillImage['희생폭탄'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/136.png';
skillImage['히든 블레이드'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/11/icon/184.png';
skillImage['히든 스팅'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/160.png';
skillImage['히든 스팅 강화'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/312.png';
skillImage['히트 블리드'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/325.png';
skillImage['히트맨의 경갑 마스터리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/15/icon/274.png';
skillImage['히트엔드'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/6/icon/008.png';
skillImage['힐 윈드'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/4/icon/032.png';
skillImage['힘의 근원'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/13/icon/284.png';
skillImage['힘의 논리'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/12/icon/427.png';

skillImage['헤이트풀'] = 'https://dundam.xyz/img/skill/464.png';
skillImage['멸진파성각'] = 'https://dundam.xyz/img/skill/430.png';
skillImage['무간업화 : 멸악참수'] = 'https://dundam.xyz/img/skill/358.png';
skillImage['비룡나선파'] = 'https://dundam.xyz/img/skill/422.png';
skillImage['뇌전격'] = 'https://dundam.xyz/img/skill/426.png';

skillImage['네오디뮴 퓨지'] = '//bbscdn.df.nexon.com/data6/commu/202201/182322_61daa98a592bd.png'
skillImage['배쉬 스톡'] = '//bbscdn.df.nexon.com/data6/commu/202201/181455_61daa78fdaeb5.png'
skillImage['부스트 : 다이너마이트 킥'] = '//bbscdn.df.nexon.com/data6/commu/202201/183858_61daad32e58a6.png'
skillImage['AT-SO Walker'] = '//bbscdn.df.nexon.com/data6/commu/202201/184234_61daae0abeb49.png'
skillImage['레이시온 크리트'] = '//bbscdn.df.nexon.com/data6/commu/202201/184322_61daae3a1932f.png'
skillImage['부스트 : 브리칭 다운'] = '//bbscdn.df.nexon.com/data6/commu/202201/182356_61daa9ac88f27.png'
skillImage['버스트 밤'] = '//bbscdn.df.nexon.com/data6/commu/202201/181428_61daa7740b345.png'
skillImage['부스트 : 파이널 데스퍼레이션'] = '//bbscdn.df.nexon.com/data6/commu/202201/184808_61daaf58f0b2a.png'
skillImage['스코치 택틱스'] = '//bbscdn.df.nexon.com/data6/commu/202201/182502_61daa9ee5f138.png'
skillImage['다이렉트 피드백'] = '//bbscdn.df.nexon.com/data6/commu/202201/182623_61daaa3f84d7a.png'
skillImage['부스트 : 서프레스'] = '//bbscdn.df.nexon.com/data6/commu/202201/180852_61daa624a7537.png'
skillImage['부스트 : 소닉 콜라이드'] = '//bbscdn.df.nexon.com/data6/commu/202201/184719_61daaf27203c8.png'
skillImage['에임 샷'] = '//bbscdn.df.nexon.com/data6/commu/202201/181329_61daa739d2b6a.png'
skillImage['포트리스 블리츠'] = '//bbscdn.df.nexon.com/data6/commu/202201/183945_61daad61711a4.png'
skillImage['부스트 : 이퀄리스-MR'] = '//bbscdn.df.nexon.com/data6/commu/202201/184555_61daaed3bea34.png'
skillImage['액티베이션'] = '//bbscdn.df.nexon.com/data6/commu/202201/181810_61daa852843a7.png'
skillImage['맥피스'] = '//bbscdn.df.nexon.com/data6/commu/202201/182424_61daa9c8344c8.png'
skillImage['아크 방전'] = '//bbscdn.df.nexon.com/data6/commu/202201/183720_61daacd0a5dd2.png'
skillImage['AT-SO Walker 자폭'] = '//bbscdn.df.nexon.com/data6/commu/202201/184234_61daae0abeb49.png'
skillImage['AT-SO Walker 자동공격'] = '//bbscdn.df.nexon.com/data6/commu/202201/184234_61daae0abeb49.png'

skillImage['트리플 건샷'] = '//bbscdn.df.nexon.com/data6/commu/202201/182250_61daa96a84305.png'
skillImage['부스트 : 바디 벙커'] = '//bbscdn.df.nexon.com/data6/commu/202201/181611_61daa7db63a62.png'
skillImage['부스트 : 데스 필드'] = '//bbscdn.df.nexon.com/data6/commu/202201/183837_61daad1d71176.png'
skillImage['더스트 샷'] = '//bbscdn.df.nexon.com/data6/commu/202201/153309_61dbd325a1203.png'

skillImage['리턴드 스나이퍼'] = 'https://dundam.xyz/img/skill/733.png';
skillImage['고전압탄 : 맥스웰'] = 'https://dundam.xyz/img/skill/737.png';
skillImage['천뢰호각'] = 'https://dundam.xyz/img/skill/348.png';
skillImage['뇌전포'] = 'https://dundam.xyz/img/skill/344.png';

skillImage['X-2 헬리오스'] = 'https://dundam.xyz/img/skill/642.png';
skillImage['포토빌라이저'] = 'https://dundam.xyz/img/skill/650.png';
skillImage['초진공탄 : 첼리'] = 'https://dundam.xyz/img/skill/646.png';

skillImage['FINALE : 특별한 이야기'] = '//bbscdn.df.nexon.com/data6/commu/202302/154947_63f3180b311b6.png';
skillImage['INTRO : 샤인 온 미'] = '//bbscdn.df.nexon.com/data6/commu/202302/154505_63f316f12b809.png';
skillImage['거리 공연'] = '//bbscdn.df.nexon.com/data6/commu/202302/152526_63f3125657911.png';
skillImage['기분 전환'] = '//bbscdn.df.nexon.com/data6/commu/202302/152519_63f3124fd5912.png';
skillImage['꿈꾸는 트릴로지'] = '//bbscdn.df.nexon.com/data6/commu/202302/154943_63f31807b1eb3.png';
skillImage['내 마음은 루바토'] = '//bbscdn.df.nexon.com/data6/commu/202302/152547_63f3126b8255a.png';
skillImage['눈부신 광경'] = '//bbscdn.df.nexon.com/data6/commu/202302/153900_63f3158488c06.png';
skillImage['다 카포'] = '//bbscdn.df.nexon.com/data6/commu/202302/152556_63f31274f3b81.png';
skillImage['달콤한 칸타빌레'] = '//bbscdn.df.nexon.com/data6/commu/202302/152620_63f3128c80645.png';
skillImage['드랍 더 비트'] = '//bbscdn.df.nexon.com/data6/commu/202302/153851_63f3157b58fac.png';
skillImage['러블리 템포'] = '//bbscdn.df.nexon.com/data6/commu/202302/152537_63f3126119aa5.png';
skillImage['럭키 크레센도'] = '//bbscdn.df.nexon.com/data6/commu/202302/154455_63f316e7a078e.png';
skillImage['밤과 꿈'] = '//bbscdn.df.nexon.com/data6/commu/202302/152400_63f3120081fe6.png';
skillImage['브랜드 뉴'] = '//bbscdn.df.nexon.com/data6/commu/202302/154446_63f316ded8a20.png';
skillImage['비비드'] = '//bbscdn.df.nexon.com/data6/commu/202302/152532_63f3125c96641.png';
skillImage['빛의 변주'] = '//bbscdn.df.nexon.com/data6/commu/202302/154459_63f316ebdab67.png';
skillImage['사운드 오브 뮤즈'] = '//bbscdn.df.nexon.com/data6/commu/202302/152625_63f31291b92ef.png';
skillImage['센세이션'] = '//bbscdn.df.nexon.com/data6/commu/202302/152419_63f312135db99.png';
skillImage['소리의 향연'] = '//bbscdn.df.nexon.com/data6/commu/202302/152408_63f31208e6cd1.png';
skillImage['손 풀기'] = '//bbscdn.df.nexon.com/data6/commu/202302/152347_63f311f32ee22.png';
skillImage['스페셜 오프닝'] = '//bbscdn.df.nexon.com/data6/commu/202302/154451_63f316e326d8d.png';
skillImage['아드레날린 랩소디'] = '//bbscdn.df.nexon.com/data6/commu/202302/152612_63f312841295b.png';
skillImage['액티브 이퀄라이저'] = '//bbscdn.df.nexon.com/data6/commu/202302/152352_63f311f87f296.png';
skillImage['에피소드 오브 하모니'] = '//bbscdn.df.nexon.com/data6/commu/202302/154939_63f318034d93b.png';
skillImage['오늘의 주인공'] = '//bbscdn.df.nexon.com/data6/commu/202302/152445_63f3122d68690.png';
skillImage['온 더 스테이지'] = '//bbscdn.df.nexon.com/data6/commu/202302/153847_63f315779dec7.png';
skillImage['유명세'] = '//bbscdn.df.nexon.com/data6/commu/202302/153843_63f31573dc124.png';
skillImage['이펙터 온 : 그루브'] = '//bbscdn.df.nexon.com/data6/commu/202302/152457_63f31239b3f01.png';
skillImage['이펙터 온 : 파워'] = '//bbscdn.df.nexon.com/data6/commu/202302/152427_63f3121bd117f.png';
skillImage['컬쳐 쇼크'] = '//bbscdn.df.nexon.com/data6/commu/202302/152439_63f3122723dfe.png';
skillImage['파워 포르테'] = '//bbscdn.df.nexon.com/data6/commu/202302/152606_63f3127ea7343.png';
skillImage['파핑파핑 스펙트럼'] = '//bbscdn.df.nexon.com/data6/commu/202302/152552_63f31270e0b1c.png';
skillImage['팬 서비스'] = '//bbscdn.df.nexon.com/data6/commu/202302/153856_63f31580875bf.png';
skillImage['핫 데뷔'] = '//bbscdn.df.nexon.com/data6/commu/202302/152405_63f31205185d7.png';
skillImage['해피한 기분'] = '//bbscdn.df.nexon.com/data6/commu/202302/152434_63f312223c819.png';

skillImage['신무성장 사지타리우스'] = '//bbscdn.df.nexon.com/data6/commu/202304/193923_6447addb4fab2.png';
skillImage['기어 드라이브 : 네뷸라'] = '//bbscdn.df.nexon.com/data6/commu/202304/193915_6447add39fcf0.png';
skillImage['신무병장 폴라리스'] = '//bbscdn.df.nexon.com/data6/commu/202304/193804_6447ad8c97b11.png';
skillImage['셋 업 : 불꽃놀이'] = '//bbscdn.df.nexon.com/data6/commu/202304/193747_6447ad7b398fc.png';
skillImage['헤이즈 스톰'] = '//bbscdn.df.nexon.com/data6/commu/202304/193737_6447ad710b4b2.png';
skillImage['셋 업 : 신무병장 요수광란'] = '//bbscdn.df.nexon.com/data6/commu/202304/193540_6447acfc8ca84.png';
skillImage['스크램블 앵커'] = '//bbscdn.df.nexon.com/data6/commu/202304/193352_6447ac90b402b.png';
skillImage['와일드 파이어'] = '//bbscdn.df.nexon.com/data6/commu/202304/193329_6447ac791c787.png';
skillImage['신무병장 유성'] = '//bbscdn.df.nexon.com/data6/commu/202304/193139_6447ac0b66102.png';
skillImage['안개폭우'] = '//bbscdn.df.nexon.com/data6/commu/202304/193103_6447abe76e1bc.png';
skillImage['기어 드라이브'] = '//bbscdn.df.nexon.com/data6/commu/202304/193041_6447abd16a216.png';
skillImage['고원의 안개꽃'] = '//bbscdn.df.nexon.com/data6/commu/202304/193025_6447abc14b37e.png';
skillImage['가랑비'] = '//bbscdn.df.nexon.com/data6/commu/202304/193015_6447abb725d00.png';
skillImage['제트스트림'] = '//bbscdn.df.nexon.com/data6/commu/202304/193008_6447abb07e324.png';
skillImage['트리플악셀'] = '//bbscdn.df.nexon.com/data6/commu/202304/192948_6447ab9c08358.png';
skillImage['독충 쫓기'] = '//bbscdn.df.nexon.com/data6/commu/202304/192939_6447ab9322526.png';
skillImage['안개무리'] = '//bbscdn.df.nexon.com/data6/commu/202304/192925_6447ab8594452.png';
skillImage['라이징 문설트'] = '//bbscdn.df.nexon.com/data6/commu/202302/133952_63f2f998bc9a4.png';
skillImage['아크로바틱 레이드'] = '//bbscdn.df.nexon.com/data6/commu/202304/192847_6447ab5fe53cd.png';
skillImage['미스트 버스트'] = '//bbscdn.df.nexon.com/data6/commu/202304/192840_6447ab58b0b7a.png';

skillImage['익사이팅'] = '//bbscdn.df.nexon.com/data6/commu/202304/192956_6447aba433be8.png';
skillImage['트레이스'] = '//bbscdn.df.nexon.com/data6/commu/202106/173443_60dc2ca3dd1c6.png';

skillImage['크루얼 인게이지'] = 'https://dundam.xyz/img/skill/3.png';
skillImage['블러드 스크래치'] = 'https://dundam.xyz/img/skill/1.png';
skillImage['브루탈 크러셔'] = 'https://dundam.xyz/img/skill/5.png';


skillImage['비셔스 래리어트'] = 'https://dundam.xyz/img/skill/fighter1.png';
skillImage['성염의 쇄도'] = 'https://dundam.xyz/img/skill/priest1.png';
skillImage['역죄단멸'] = 'https://dundam.xyz/img/skill/priest3.png';


skillImage['슈퍼 체이서'] = 'https://dundam.xyz/img/skill/mage1.png';
skillImage['황룡강림'] = 'https://dundam.xyz/img/skill/mage2.png';

skillImage['거부'] = 'https://bbscdn.df.nexon.com/data6/commu/202403/e783ff0f-e381-fc43-2d8b-bb553d31efd2.png';
skillImage['극야'] = 'https://bbscdn.df.nexon.com/data6/commu/202403/99cd9a8b-4e83-0b6d-fb2f-5cd585ca1ea4.png';
skillImage['넥 펀처'] = 'https://bbscdn.df.nexon.com/data6/commu/202403/7d75fd97-303c-5385-4a61-eefc91579d2d.png';
skillImage['도깨비 바람'] = 'https://bbscdn.df.nexon.com/data6/commu/202403/abe6d500-66f8-2207-d788-11450322792c.png';
skillImage['동족 사냥꾼'] = 'https://bbscdn.df.nexon.com/data6/commu/202403/4f239c9e-c9f8-1b59-8f91-642efb0bdc62.png';
skillImage['디바우러'] = 'https://bbscdn.df.nexon.com/data6/commu/202403/f3d1c83d-6c93-8f41-e54c-13095fec0683.png';
skillImage['램페이지'] = 'https://bbscdn.df.nexon.com/data6/commu/202403/1e300277-e432-fa98-bf13-3a2551b3cd72.png';
skillImage['만물의 정점'] = 'https://bbscdn.df.nexon.com/data6/commu/202403/4d1ffbbf-1eb2-e0b1-b330-0e81b2234fd2.png';
skillImage['발골'] = 'https://bbscdn.df.nexon.com/data6/commu/202403/28f47b13-755c-4c9c-9272-ae276cd36b1e.png';
skillImage['발톱 꺼내기'] = 'https://bbscdn.df.nexon.com/data6/commu/202403/05f6b26a-b73b-d483-756d-c060987017c0.png';
skillImage['발톱 문신'] = 'https://bbscdn.df.nexon.com/data6/commu/202403/b3d48a03-45d0-565e-3a29-08df70a04e5c.png';
skillImage['부여된 야성'] = 'https://bbscdn.df.nexon.com/data6/commu/202403/652482e3-c721-ad42-4388-ea38b4f7be66.png';
skillImage['사냥꾼의 인사법'] = 'https://bbscdn.df.nexon.com/data6/commu/202403/c43b52c4-5d7e-df8d-31d3-64604be55e7b.png';
skillImage['산 제물의 연회'] = 'https://bbscdn.df.nexon.com/data6/commu/202403/3c278384-bbef-c450-48b0-27ef8112446f.png';
skillImage['숨을 탐하는 자'] = 'https://bbscdn.df.nexon.com/data6/commu/202403/e05af78f-1d0e-5f2d-c7aa-d47d2b963f18.png';
skillImage['신요의 발톱'] = 'https://bbscdn.df.nexon.com/data6/commu/202403/86baccb4-adec-a297-08b6-996fb12a152c.png';
skillImage['아웃버스트'] = 'https://bbscdn.df.nexon.com/data6/commu/202403/2782c8fc-b2af-b2f2-dc5b-af2e5d184679.png';
skillImage['안면 할퀴기'] = 'https://bbscdn.df.nexon.com/data6/commu/202403/08032d4d-9f7e-06b8-0084-0d7bc2b097af.png';
skillImage['요수조'] = 'https://bbscdn.df.nexon.com/data6/commu/202403/c7430465-2b7a-8654-80f5-16030b3dc238.png';
skillImage['요요만찬'] = 'https://bbscdn.df.nexon.com/data6/commu/202403/af1054f9-0134-a873-d125-084328fc4ac9.png';
skillImage['인요합의'] = 'https://bbscdn.df.nexon.com/data6/commu/202403/503c5a8b-ee6a-0dc3-6990-b2e9e46f24ee.png';
skillImage['쫓는 뱀'] = 'https://bbscdn.df.nexon.com/data6/commu/202403/7302b6f6-54c2-41c3-60d1-5df8a818883f.png';
skillImage['커팅 섀도우'] = 'https://bbscdn.df.nexon.com/data6/commu/202403/59dc0de1-8456-896e-15b0-54a5e23bb668.png';
skillImage['크루얼 슬러터'] = 'https://bbscdn.df.nexon.com/data6/commu/202403/a31ec2b8-84a6-5a07-54ee-3b68335eb9b0.png';
skillImage['특이체질'] = 'https://bbscdn.df.nexon.com/data6/commu/202403/2e8c7724-3d2f-4ecd-a8af-941469359911.png';
skillImage['페인 브링어'] = 'https://bbscdn.df.nexon.com/data6/commu/202403/505d841c-fb55-b79b-4436-e025b02cf91f.png';
skillImage['피스 피어서'] = 'https://bbscdn.df.nexon.com/data6/commu/202403/6aa5cf23-4750-427f-0335-dd19d0d2a08e.png';
skillImage['하악질'] = 'https://bbscdn.df.nexon.com/data6/commu/202403/8faa3db6-5443-ea55-1ea8-b4e847b9b16b.png';
skillImage['확인 사살'] = 'https://bbscdn.df.nexon.com/data6/commu/202403/baa6c0c2-9bb1-b688-662e-fb49bd31fcf5.png';

skillImage['F.D. 피어서'] = 'https://bbscdn.df.nexon.com/data6/commu/202403/81c9dc2d-0068-b53e-a1c6-c149ad5820c6.png';
skillImage['기어 업그레이드!'] = 'https://bbscdn.df.nexon.com/data6/commu/202403/89a6c060-7edf-715a-4d0a-ee109abd0863.png';
skillImage['놓치지마, 팔케!'] = 'https://bbscdn.df.nexon.com/data6/commu/202403/33665b1d-dfb4-592a-e182-c9afcb2f6dcc.png';
skillImage['래피드샷'] = 'https://bbscdn.df.nexon.com/data6/commu/202403/fd29915f-735a-796b-8e92-650cabfca9b9.png';
skillImage['매시브 피어서'] = 'https://bbscdn.df.nexon.com/data6/commu/202403/25d63ee6-78ba-a785-9c32-76a7ab735bbb.png';
skillImage['밤 슈터'] = 'https://bbscdn.df.nexon.com/data6/commu/202403/04c5317e-462c-bd75-717c-f1f9dbce0334.png';
skillImage['베테랑 헌터의 훈장'] = 'https://bbscdn.df.nexon.com/data6/commu/202403/3f684130-d238-324e-98dc-342dc34919f0.png';
skillImage['볼트 레인'] = 'https://bbscdn.df.nexon.com/data6/commu/202403/5b70064e-733b-fee7-6f9d-c9ec43f68a6c.png';
skillImage['부스트 기어'] = 'https://bbscdn.df.nexon.com/data6/commu/202403/3f4eda32-9c82-5c4f-4360-1b8eeb96afce.png';
skillImage['사냥감 식별'] = 'https://bbscdn.df.nexon.com/data6/commu/202403/70d136d4-b035-3dae-2806-0d601e70bc87.png';
skillImage['사냥을 시작해볼까!'] = 'https://bbscdn.df.nexon.com/data6/commu/202403/0191f33f-5eb8-ebdb-7096-3b66512e17f4.png';
skillImage['세컨드 부스트'] = 'https://bbscdn.df.nexon.com/data6/commu/202403/2a00cc39-715c-1edb-0d74-8e08d4aa4616.png';
skillImage['스프링클러'] = 'https://bbscdn.df.nexon.com/data6/commu/202403/ecc128d7-cbe2-0cb5-d37a-b43ef40d2f6e.png';
skillImage['쓰리 인 어 베드'] = 'https://bbscdn.df.nexon.com/data6/commu/202403/d0465205-7627-6439-ebf9-d0dad6cc0a9b.png';
skillImage['워닝 샷건'] = 'https://bbscdn.df.nexon.com/data6/commu/202403/08cc3cae-2778-d65e-0d92-841797d17f38.png';
skillImage['조인트 : 디몰리셔'] = 'https://bbscdn.df.nexon.com/data6/commu/202403/e4f56aa5-37f1-5ccc-8b7b-f7b96fa1ff7d.png';
skillImage['조인트 : 라운드업'] = 'https://bbscdn.df.nexon.com/data6/commu/202403/d6070723-a42a-67a2-c5e3-142222e70cd4.png';
skillImage['조인트 : 카펫 바밍'] = 'https://bbscdn.df.nexon.com/data6/commu/202403/7057963c-bd39-6e4c-d0a7-29556c8de5f7.png';
skillImage['조인트 : 터닝 포인트'] = 'https://bbscdn.df.nexon.com/data6/commu/202403/2e2ec316-4033-057a-c3e7-ca8388004faa.png';
skillImage['크로스슈터 마스터리'] = 'https://bbscdn.df.nexon.com/data6/commu/202403/46f779eb-27e5-4b9d-dfd7-65c80386654d.png';
skillImage['팔케 : 블레이드 페더'] = 'https://bbscdn.df.nexon.com/data6/commu/202403/3a791ea3-f54b-d80f-f228-1499c69a062b.png';
skillImage['팔케 : 웹 드롭'] = 'https://bbscdn.df.nexon.com/data6/commu/202403/e1b9d0f2-4afc-63b9-6a3c-2d4d5d831f2a.png';
skillImage['팔케 : 윙 커터'] = 'https://bbscdn.df.nexon.com/data6/commu/202403/f07f62c4-962f-da25-1c35-f6ab1cafed97.png';
skillImage['팔케 : 체이싱클러'] = 'https://bbscdn.df.nexon.com/data6/commu/202403/f04232a8-a4f5-f24e-465b-1b59e0370544.png';
skillImage['팔케 : 컨퓨징 무브'] = 'https://bbscdn.df.nexon.com/data6/commu/202403/50dc9251-ed84-68ce-deaf-6706f35e11d2.png';
skillImage['팔케 : 퀵 페킹'] = 'https://bbscdn.df.nexon.com/data6/commu/202403/c75d9e25-4165-d4cc-bb95-b35496d7da6d.png';
skillImage['패스트 트리거'] = 'https://bbscdn.df.nexon.com/data6/commu/202403/e8f230b1-6695-668d-c3ce-0b6df7ed6054.png';
skillImage['퍼펙트 에이밍'] = 'https://bbscdn.df.nexon.com/data6/commu/202403/b9808520-af20-d7ef-7f07-6078b22c37cc.png';
skillImage['플레어'] = 'https://bbscdn.df.nexon.com/data6/commu/202403/4eace8af-af6e-6f61-c20c-707362f620a2.png';
skillImage['하이프레셔'] = 'https://bbscdn.df.nexon.com/data6/commu/202403/434c6425-5150-71d2-d359-24f50d0bd416.png';



skillImage['열의 식신 - 주작'] = 'https://dundam.xyz/img/skill/P-7.png';
skillImage['지의 식신 - 현무'] = 'https://dundam.xyz/img/skill/P-9.png';
skillImage['공의 식신 - 백호'] = 'https://dundam.xyz/img/skill/P-11.png';
skillImage['사좌천태문'] = 'https://dundam.xyz/img/skill/P-13.png';
skillImage['해의 식신 - 창룡'] = 'https://dundam.xyz/img/skill/P-15.png';
skillImage['황룡진'] = 'https://dundam.xyz/img/skill/P-19.png';
skillImage['아포칼립스'] = 'https://dundam.xyz/img/skill/P-21.png';

skillImage['스피닝 스플래시'] = 'https://dundam.xyz/img/skill/fighter_1.png';
skillImage['광룡승천파'] = 'https://dundam.xyz/img/skill/fighter_7.png';
skillImage['섬광환격'] = 'https://d-fighter.dn.nexoncdn.co.kr/samsungdnf/neople/swf/2019/skill/1/icon/316.png';



export default skillImage;
